import React, { useEffect } from "react";
import "assets/components/faq.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

const Faq = (props) => {
  return (
    <>
      <div id="Faq" className="container container-section-6">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h1 className="color1 title-section-2 ms-3">Faq</h1>
            <h2 className="color2 fs-4 ms-3" style={{ fontWeight: 700 }}>
              Chiariamo tutti i dubbi riguardo Denuncia Online
            </h2>
            <div className="accordion mt-5" id="accordionExample">
              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Come funziona nel dettaglio il servizio?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      <strong>
                        La procedura è semplicissima, i passaggi sono i
                        seguenti:
                      </strong>
                      <br />
                    </p>
                    <p className="my-2">
                      <strong>1. Accesso:</strong>
                      <br />
                      Registrarti con il tuo indirizzo email o accedere con il
                      tuo account social.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong>2. Compilazione della denuncia:</strong>
                      <br />
                      Nella tua dashboard puoi scegliere il modello di denuncia
                      di cui hai bisogno. Una volta scelto, ti basterà compilare
                      pochi semplici campi per inserire le tue generalità e
                      descrivere il fatto accaduto. Il documento di denuncia
                      sarà generato automaticamente dal nostro sistema.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong>3. Firma:</strong>
                      <br />
                      Per la firma della denuncia basterà caricare una foto del
                      tuo documento di riconoscimento (passaporto o carta di
                      identità). Ti verrà inviato un codice OTP sul tuo
                      smartphone e la tua firma digitale sarà apposta.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong> 4. Invio della pratica:</strong>
                      <br />
                      Il nostro team verifica che hai firmato correttamente il
                      documento. La denuncia viene quindi inviata da un avvocato
                      alla procura competente tramite gli appositi canali
                      informatici.
                      <br />
                    </p>

                    <p className="my-2">
                      <strong>5. Ricevuta di deposito:</strong>
                      <br />I sistemi della procura ci restituiranno la ricevuta
                      di deposito della denuncia (da utilizzare per tutti gli
                      scopi ammessi dalla legge). Caricheremo la ricevuta nella
                      tua dashboard e potrai scaricarla facilmente.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Cosa posso denunciare?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Puoi presentare una denuncia per tutte le principali
                    categorie di reati (furti, danni, atti vandalici, reati
                    informatici, etc.). Ti basta selezionare il modello
                    desiderato nella tua dashboard. In caso di dubbi potrai
                    sempre contattarci. Non è possibile denunciare lo
                    smarrimento di oggetti in quanto tale fatto non costituisce
                    reato.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    Cosa posso fare se non trovo uno specifico modello di
                    denuncia?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Contattaci in chat, ti forniremo prontamente ogni
                    informazione.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Quali documenti e dati servono?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sono sufficienti i tuoi dati anagrafici, le informazioni sul
                    fatto da denunciare e un tuo documento in corso di validità
                    (carta di identità o passaporto. Non è ammessa la patente).
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFor">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFor"
                    aria-expanded="false"
                    aria-controls="collapseFor"
                  >
                    Il servizio è interamente online?
                  </button>
                </h2>
                <div
                  id="collapseFor"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFor"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sì, non devi recarti fisicamente in nessun comando o
                    questura. Puoi completare l’intera procedura dal tuo pc o
                    smartphone.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Quali sono i vantaggi del servizio?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Con il nostro servizio puoi comodamente sporgere una
                    denuncia ovunque ti trovi. Eviti perdite di tempo per
                    spostamenti in città e lunghe code negli uffici. Non dovrai
                    chiedere permessi o ferie a lavoro.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Quali sono le tempistiche?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Per creare e firmare la tua denuncia bastano pochi minuti.
                    Invieremo la pratica prontamente e otterrai la ricevuta da
                    parte della procura competente entro poche ore. Le
                    tempistiche possono allungarsi durante il fine settimana e
                    nei giorni festivi in genere.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Il servizio è sicuro?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Certamente, il servizio ha pieno valore legale. La ricevuta
                    che ottieni è del tutto equiparabile a quella rilasciata ai
                    comandi o in questura.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Il servizio prevede un abbonamento?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Assolutamente no, non ci sarà nessun addebito automatico.
                    Paghi solo per la singola pratica di denuncia.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Come posso pagare?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Puoi acquistare comodamente il servizio tramite PayPal
                    (carta o conto collegati). Siamo al lavoro per offrirti
                    presto nuovi metodi di pagamento.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    Se durante la procedura ho dei dubbi posso contattarvi?
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Certamente, puoi contattarci in ogni momento per chiarire
                    ogni tuo dubbio.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingTwelve">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    Posso denunciare fatti accaduti fuori dall'Italia?
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Purtroppo no. Possiamo accettare denunce solo per fatti che
                    si sono verficati all'interno del territorio nazionale.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingThirteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    Il servizio funziona in tutta Italia?
                  </button>
                </h2>
                <div
                  id="collapseThirteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Sì, siamo operativi per l’intero territorio nazionale.
                  </div>
                </div>
              </div>

              <div className="accordion-item color1">
                <h2 className="accordion-header" id="headingFourteen">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    Se sono minorenne posso usare il vostro servizio?
                  </button>
                </h2>
                <div
                  id="collapseFourteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFourteen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Purtroppo no, è necessario che tu sia maggiorenne.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
