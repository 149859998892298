import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderLogo from "components/ComponentHtml/HeaderLogo";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import CookiePolicyComponent from "components/ComponentHtml/CookiePolicy";

export default function CookiePolicy() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "none" }} />
      ) : (
        <>
          <HeaderLogo />
          <CookiePolicyComponent />
          <FooterOnePage />
        </>
      )}
    </>
  );
}
