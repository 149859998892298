import { Typography } from '@mui/material';
import React from 'react';
import '../assets/components/text-simple.scss';


const TextSimple = ({children}) => {
    return <div className="TextSimple">
                <Typography>{children}</Typography>
        </div>
}

export default TextSimple;