import React from 'react';

import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import EnvInfo from 'layout-blueprints/EnvInfo';



const MinimalLayout = (props) => {
  const { children } = props;

  return   <div className="MinimalLayout" >
    <EnvInfo></EnvInfo>
    <Header />
    <Box padding={0} marginBottom="150px" textAlign="center" >
      <Container maxWidth="md" >
      {children}
      </Container>
    </Box>
    <Footer />
  </div>;
};

MinimalLayout.propTypes = {
  children: PropTypes.node
};

export default MinimalLayout;
