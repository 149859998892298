import React, { useEffect } from "react";
import "assets/components/CardBlog.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { KeyboardDoubleArrowRightOutlined } from "@mui/icons-material";
import { useState } from "react";
import "assets/components/BlogDetail.scss";

const CardBlog = (props) => {
  const [idSlug, setIdSlug] = useState(true);
  const [plainText, setPlainText] = useState("");

  useEffect(() => {
    if (props.slug !== null) {
      setIdSlug(props.slug);
    } else {
      setIdSlug(props.id);
    }

    const parseHTML = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    setPlainText(parseHTML(props.content || ""));
  }, [props.content, props.id, props.slug]);

  return (
    <>
      <Card
        className="CardBlog"
        sx={{
          position: "relative",
          width: "345px",
          height: "320px",
          marginRight: "25px",
          marginLeft: "25px",
          marginBottom: "50px",
          boxShadow: "6px 6px 8px 0 rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={
            props.featured_image
              ? `data:image/png;base64,${props.featured_image}`
              : ""
          }
        />
        <CardContent>
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: 500,
              marginBottom: "10px",
              color: "#20305B",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            className="textDescriptionCard"
            variant="body2"
            color="text.secondary"
          >
            {plainText && plainText.length > 130
              ? plainText.slice(0, 130) + "..."
              : plainText}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <Link href={`/b/${idSlug}`} size="small">
            Leggi{" "}
            <KeyboardDoubleArrowRightOutlined
              style={{ fontSize: "16px", marginLeft: "4px" }}
            />
          </Link>
        </CardActions>
      </Card>
    </>
  );
};

export default CardBlog;
