import React, { useEffect } from "react";
import "assets/components/cardTrustpilot.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid, Typography } from "@mui/material";

const CardTrustpilot = (props) => {
  const { name, content, gender } = props.reviewItem;

  return (
    <>
      <div className="TrustPilotCardContainer">
        <Grid
          container
          className="TrustPilotCard"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={11}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "8px",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <div
              className={gender === "female" ? "svg-female" : "svg-male"}
            ></div>
            <Typography className="textContentCardTrustpilot">
              {`"${content}"`}
            </Typography>

            <Typography
              style={{
                marginTop: "10px",
                color: "#00000066",
                fontWeight: 600,
                fontSize: "12px",
              }}
            >
              {name}
            </Typography>

            <div className="starsTrustpilot"></div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CardTrustpilot;
