import React, { Fragment, useEffect, useState } from "react";
import { Grid, TextField, Container, Typography, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../history";
import { actions } from "../../_model/report/actions";
import { useLocation } from "react-router-dom";
import TextSimple from "components/TextSimple";
import ButtonPrimary from "components/ButtonPrimary";
import ButtonTertiary from "components/ButtonTertiary";
import TextInfobox from "components/TextInfobox";
import TextHeaderSecondary from "components/TextHeaderSecondary";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function ResetPassword() {
  const token = useQuery().get("token");

  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const errors = useSelector((state) => state.report.errors);

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setError(false);
    setSent(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = (event) => {
    // event.preventDefault();
    const { password, password_confirmation } = formState.values;
    dispatch(actions.passwordReset(token, password, password_confirmation));
    setSent(false);
    setTimeout(() => {
      setSent(true);
    }, 2000);
  };

  useEffect(() => {
    setError(errors !== null);
  }, [errors]);

  const customer = useSelector((state) => state.report.customer);

  useEffect(() => {
    if (customer) {
      history.push("/dashboard");
    }
  }, [customer]);

  return (
    <>
      <Container className="PageLogin" maxWidth="sm">
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <TextSimple>Imposta la tua nuova password</TextSimple>
          </Grid>

          {sent && !error ? (
            <Grid item xs={12}>
              <TextInfobox status="complete">
                <Typography
                  className="info-text-title"
                  style={{ textAlign: "start" }}
                >
                  Password aggiornata.
                </Typography>
                <Typography className="info-text-subtitle">
                  Ritorna alla pagina di login per effettuare l'accesso.
                </Typography>
              </TextInfobox>
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  fullWidth
                  name="password"
                  value={formState.values.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  helperText={errors && errors.password}
                  fullWidth
                  name="password_confirmation"
                  value={formState.values.password_confirmation}
                  onChange={handleChange}
                  placeholder="Conferma Password"
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <ButtonPrimary className="button-custom" onClick={handleSignIn}>
                  Imposta nuova password
                </ButtonPrimary>
              </Grid>
            </Fragment>
          )}

          <Grid item xs={12}>
            <Typography style={{ color: "#ffffff" }}>
              Ritorna alla pagina di{" "}
              <Link
                href="/login"
                style={{
                  color: "#ffffff",
                  textDecoration: "underline",
                  textDecorationColor: "#ffffff",
                }}
              >
                Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
