import React, { Fragment } from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

export { ZnButton };

const ZnButton = (props) => {
  const {
    children,
    onClick = () => {},
    color = "",
    confirm = false,
    confirmTitle = "confirmTitle",
    confirmText = "confirmText",
    confirmTextOne = "confirmTextOne",
    confirmTextTwo = "confirmTextOne",
    confirmTextThree = "confirmTextOne",
    checkbox = false,
    label = "",
    confirmYes = "Yes",
    confirmNo = "No",
  } = props;

  const [modalOn, setModalOn] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);

  const handleOnClick = () => {
    if (confirm) {
      setModalOn(true);
      return;
    }
    onClick();
  };

  const handleNo = () => {
    setModalOn(false);
  };
  const handleYes = () => {
    setModalOn(false);
    if (checkbox === true) {
      if (confirmChecked) {
        onClick();
      }
    } else {
      onClick();
    }
  };

  const handleCheckboxChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  return (
    <Fragment>
      <Button {...props} onClick={handleOnClick} color={color}>
        {children}
      </Button>
      <Dialog
        open={modalOn}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ padding: "5px" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#20305b", fontWeight: 600, padding: "10px" }}
        >
          <Typography
            sx={{ color: "#20305b", fontWeight: 600, padding: "12px" }}
          >
            {confirmTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#20305b" }}
          >
            {confirmText}
            <Typography>{confirmTextOne}</Typography>
            <Typography>{confirmTextTwo}</Typography>
            <Typography>{confirmTextThree}</Typography>
            {checkbox === true ? (
              <FormControlLabel
                style={{ marginTop: "10px" }}
                control={
                  <Checkbox
                    checked={confirmChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                    sx={{ marginTop: "10px" }}
                  />
                }
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "12px !important",
                    fontFamily: "Quicksand, sans-serif !important",
                    marginTop: "10px",
                  },
                }}
                label={label}
              />
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={handleNo}
            sx={{
              textTransform: "inherit",
              backgroundColor: "transparent !important",
              color: "#20305b !important",
              border: "1px solid #20305b",
              borderRadius: "10px",
              transition: "0.4s ease",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1) !important",
                opacity: 0.5,
              },
            }}
            autoFocus
          >
            {confirmNo}
          </Button>
          <Button
            onClick={handleYes}
            sx={{
              textTransform: "inherit",
              backgroundColor: "#58979d !important",
              color: "#fff !important",
              borderRadius: "10px",
              transition: "0.4s ease",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: "#58979d",
                filter: "brightness(0.9)",
              },
              "&:disabled": {
                backgroundColor: "#a0a0a078 !important",
                color: "#20305b !important",
              },
            }}
            disabled={checkbox ? !confirmChecked : null}
          >
            {confirmYes}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
