export const SET_USER = 'AUTH/SET_USER';
export const setUser = (user) => {

  return {
    type: SET_USER,
    user
  };
};

export default function reducer(state = {
  user: true,
}, action) {

  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
  
}