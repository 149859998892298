import { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { setUser } from '../../reducers/User';
import { history } from '../../history';

export default function Logout() {

  const dispatch = useDispatch();

  const authenticated = useSelector(state => state.User.user);

  const checkAuth = () => {
    if(authenticated) {
      dispatch(setUser(null))
      history.push('/');
    }
  }

  useEffect(checkAuth, [authenticated])
  useEffect(checkAuth)
  

  return null;
}
