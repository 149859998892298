import React, { useEffect, useState, useRef } from "react";
import "assets/components/infoHome.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-three.json";
import animationCounter from "assets/img/animation-counter.json";
import Lottie from "lottie-web";
import logoTrustpilot from "assets/img/Trustpilot.png";
import { Grid, Typography } from "@mui/material";
import CardTrustpilot from "./CardTrustpilot";
import reviewTrustPilot from "../../assets/trustpilot.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfoHome = (props) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [targetCount, setTargetCount] = useState(3500);
  const duration = 5000;
  const step = 20;

  const counterRef = useRef(null);

  useEffect(() => {
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const weeklyIncrease = 20;

    const increaseTargetCount = () => {
      setTargetCount((prevTargetCount) => prevTargetCount + weeklyIncrease);
    };

    const intervalIncrease = setInterval(
      increaseTargetCount,
      oneWeekInMilliseconds
    );

    increaseTargetCount();

    return () => {
      clearInterval(intervalIncrease);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = counterRef.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const isVisible =
        rect.top <= window.innerHeight && rect.bottom >= window.innerHeight;

      if (isVisible && currentCount < targetCount) {
        const interval = setInterval(() => {
          setCurrentCount((prevCount) => {
            const nextCount = prevCount + step;
            return nextCount < targetCount ? nextCount : targetCount;
          });
        }, 10);

        setTimeout(() => {
          clearInterval(interval);
        }, duration);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentCount, targetCount]);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-counter");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationCounter,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  // codice carousel card

  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCardIndex((prevIndex) =>
        prevIndex === reviewTrustPilot.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [currentCardIndex, reviewTrustPilot.length]);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  return (
    <>
      <Grid container className="bg-section-7" ref={counterRef}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography className="title">
            Servizio "eccellente", lo dice chi ci ha scelto
          </Typography>

          <Grid item xs={10} className="containerSlider">
            <Slider {...settings}>
              {reviewTrustPilot.map((item, index) => (
                <CardTrustpilot key={index} reviewItem={item} />
              ))}
            </Slider>
          </Grid>

          <div className="d-flex justify-content-center">
            <div className="col-10 mt-5 containerAction">
              <div className="col-12 col-sm-5 col-md-4 col-lg-3 mt-3">
                <div className="d-flex containerTrustpilot">
                  <img src={logoTrustpilot} width="150" />
                </div>
                <div className="trustpilot">
                  <div
                    data-locale="it-IT"
                    data-template-id="56278e9abfbbba0bdcd568bc"
                    data-businessunit-id="648751c616b6170574592cfe"
                    data-style-height="52px"
                    data-style-width="100%"
                    className="trustpilotHome"
                  >
                    <a
                      href="https://it.trustpilot.com/review/denunciaonline.eu"
                      target="_blank"
                      rel="noopener"
                    >
                      Vedi tutte le recensioni
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-5 col-md-4 col-lg-3 marginButton">
                <div className="col-xs-12 col-lg-7 mx-auto d-flex justify-content-center">
                  <a href="/register" className="btn btn-section-5">
                    Inizia subito
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* codice contatore al momento disabilitato */}
      {/* <div className="col-xs-12 col-sm-5 col-lg-3 mt-5 box-contatti">
        <h1
          className="title-section-counter"
          style={{
            color: "#ffffff",
            textAlign: "center",
          }}
        >
          +{currentCount}
        </h1>
        <h2
          className="text-section-counter mt-3"
          style={{
            color: "#ffffff",
            textAlign: "center",
            marginLeft: "20px",
          }}
        >
          Ore già risparmiate
        </h2>
        <h2
          className="text-section-counter"
          style={{
            color: "#ffffff",
            textAlign: "center",
            marginLeft: "20px",
          }}
        >
          da cittadini e forze dell'ordine
        </h2>
        <div
          id="lottie-animation-counter"
          className="container-animation-counter"
        ></div>
      </div> */}

      {/* codice animazione  */}
      {/* <div
            id="lottie-animation-three"
            className="container-animation-three">
      </div> */}
    </>
  );
};

export default InfoHome;
