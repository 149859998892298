import React from "react";
import { history } from "../history";
import "../assets/components/logo-header.scss";
import logo from "../assets/img/denuncia.online_logo_negative.png";
import logoDas from "../assets/img/logo-das.png";
import { Fragment } from "react";

const LogoHeader = ({ goto = "/dashboard", children }) => {
  const handleLogoClick = () => {
    history.push(goto);
  };

  const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
  const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

  return (
    <Fragment>
      {partner === true ? (
        <div className="LogoHeader">
          <h1 style={{ cursor: "pointer" }} className="logo">
            <a href={goto}>
              <img width={160} src={logo} alt="DenunciaOnline.eu" />
            </a>
            <span style={{ color: "#fff", marginRight: "10px" }}>x</span>
            <a>
              <img width={50} src={logoDas} alt="DenunciaOnline.eu" />
            </a>
          </h1>
        </div>
      ) : (
        <div className="LogoHeader">
          <h1 style={{ cursor: "pointer" }} className="logo">
            <a href={goto}>
              <img width={160} src={logo} alt="DenunciaOnline.eu" />
            </a>
          </h1>
        </div>
      )}
    </Fragment>
  );
};

export default LogoHeader;
