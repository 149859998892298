import { Button, Typography } from "@mui/material";
import React from "react";
import "../assets/components/button-secondary.scss";

const ButtonSecondary = (props) => {
  return (
    <div
      className={
        props.consulenza ? "ButtonSecondaryConsulenza" : "ButtonSecondary"
      }
    >
      <Button
        disableElevation
        {...props}
        fullWidth
        size="large"
        color="primary"
        variant="outlined"
        type="submit"
      >
        {props.children}
      </Button>
    </div>
  );
};

export default ButtonSecondary;
