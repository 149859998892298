import { combineReducers } from 'redux';
import {reducer as user} from './user/reducer';
import {reducer as report} from './report/reducer';
import {reducer as comuni} from './comuni/reducer';

const rootReducer = combineReducers({
  comuni,
  report
});

export default rootReducer;