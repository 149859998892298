import React from 'react';

import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LogoHeader from '../../components/LogoHeader';
import Footer from '../../components/Footer';
import EnvInfo from 'layout-blueprints/EnvInfo';


const BaseLayout = (props) => {
  const { children } = props;

  return <>
    <EnvInfo></EnvInfo>
    <Box padding={5} textAlign="center" >
      <LogoHeader goto="/"></LogoHeader>
    </Box>
    <Box padding={0} paddingBottom={5} marginBottom={5} textAlign="center">
      {children}
    </Box>
    {/* <Footer /> */}
  </>;
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
