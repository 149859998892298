import React from "react";
import { Container, Box, AppBar, Toolbar, Typography } from "@mui/material";
import { history } from "../history";
import { actions } from "_model/report";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu';
import Home from "@mui/icons-material/Home";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import LogoHeader from "./LogoHeader";
import "../assets/components/header.scss";
import { useEffect } from "react";

const Header = () => {
  const customer = useSelector((state) => state.report.customer);

  useEffect(() => {
    if (customer === undefined) {
      history.push("/login");
    }
  }, [customer]);

  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    dispatch(actions.logout());
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    history.push(event.currentTarget.dataset.route);
  };

  return (
    <AppBar className="Header" color="transparent" elevation={0}>
      <Container padding="2" maxWidth="lg" style={{ maxWidth: "1450px" }}>
        <Toolbar disableGutters className="HeaderItems">
          <LogoHeader></LogoHeader>
          <Box component="div" sx={{ flexGrow: 1 }} />

          <IconButton
            className="Profile"
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            {/* <MenuIcon /> */}
            {/* <Home /> */}
            <div className="icon-home-dashoard" />
          </IconButton>
          <Menu
            marginThreshold={0}
            elevation={0}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              sx={{
                fontFamily: "Quicksand, sans-serif",
                fontWeight: 500,
                color: "#20305b",
              }}
              data-route="/dashboard"
              onClick={handleClose}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              onClick={handleLogoutClick}
              sx={{
                fontFamily: "Quicksand, sans-serif",
                fontWeight: 500,
                color: "#20305b",
              }}
            >
              Esci
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
