import { constants } from "./constants";

const initialState = {
  // status: null,
  // userEmail: null,
  // documentId: null,
  // digitalSing: null,
  // totalPrice: null,
  // payed: null,
  // requestStatus: 1,
  customer: null,
  report: null,
  reports: [],
  reportData: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.ACTION_REQUEST:
      return {
        ...state,
        errors: null,
        requestStatus: -1,
      };
    case constants.ACTION_SUCCESS:
      return {
        ...state,
        ...action.data.data,
        reportData: action.data,
        requestStatus: 1,
      };
    case constants.ACTION_FAILURE:
      return {
        ...state,
        requestStatus: 0,
        errors: {
          ...action.error.errors,
        },
      };
    // return initialState;

    default:
      return state;
  }
}
