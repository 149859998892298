import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Box,
  Chip,
  Button,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Link,
} from "@mui/material";
import { Card } from "@material-ui/core";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../assets/components/report-data-card.scss";
import Moment from "react-moment";
import "moment/locale/it";
import { history } from "../history";

const StatusChip = ({ memo }) => {
  if ("Completata" === memo.status)
    return (
      <Typography className="CardStatus">
        <Chip
          className="CardStatusCircle"
          // label="&nbsp;&nbsp;"
          color="success"
        />{" "}
        Completata
      </Typography>
    );

  if ("Archiviata" === memo.status)
    return (
      <Typography className="CardStatus">
        <Chip
          className="CardStatusCircle"
          // label="&nbsp;&nbsp;"
          // color="warning"
          sx={{ backgroundColor: "#FF0F00 !important" }}
        />{" "}
        Archiviata
      </Typography>
    );

  return (
    <Typography className="CardStatus">
      <Chip
        className="CardStatusCircle"
        style={{ backgroundColor: "#ed6c02" }}
      />{" "}
      <span style={{ marginTop: "5px" }}>Prenotata</span>
    </Typography>
  );
};

export default function CalendlyReportCard({ memo, showCta }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    history.push("/dashboard/pratica/consulenza/" + memo.id);
  };

  const handleButtonClick = () => {
    window.Calendly.initPopupWidget({
      url: `https://calendly.com/reschedulings/${memo.invitee_uuid}`,
    });
    return false;
  };

  return (
    <>
      <Card className="ReportDataCard" elevation={0}>
        <Box
          className="ReportDataCardIconCalendly"
          padding={1}
          paddingRight={0}
          display="flex"
          alignContent="center"
          justifyContent="center"
        >
          <div className="icon-dashboard-consulenza-card" />
        </Box>
        <CardContent sx={{ flex: "1 0" }}>
          <Box textAlign="left">
            <StatusChip memo={memo} />
            <Typography className="CardDate">
              Consulenza del{" "}
              <strong>
                <Moment locale="it" format="DD MMMM YYYY, HH:mm">
                  {memo.event_start_time}
                </Moment>
              </strong>
            </Typography>
            <Typography className="CardType">#{memo.id}</Typography>
          </Box>
        </CardContent>

        {showCta === true && (
          <Box padding={1}>
            <Button
              className="button-card"
              variant="contained"
              onClick={onClick}
              size="large"
              sx={{
                height: "100%",
                minWidth: 0,
                padding: "0.2em",
                backgroundColor: "#58979d",
              }}
            >
              <ArrowForwardIosIcon></ArrowForwardIosIcon>
            </Button>
          </Box>
        )}
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          width: "500px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%", xl: "30%" },
              bgcolor: "#fff",
              borderRadius: "4px",
              padding: "20px",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              style={{ color: "#20305b" }}
            >
              Dettagli della Consulenza
            </Typography>
            <Typography sx={{ mt: 2 }} style={{ color: "#20305b" }}>
              Consulenza prenotata per il{" "}
              <strong>
                <Moment locale="it" format="DD MMMM YYYY, HH:mm">
                  {memo.event_start_time}
                </Moment>
              </strong>
            </Typography>

            <Grid
              item
              xs={12}
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <div className="icon-back-dashoard-modal" />
              </div>
              <div
                onClick={handleButtonClick}
                style={{ display: "flex", gap: "10px", cursor: "pointer" }}
              >
                <a
                  target="_black"
                  className="link-update-call-calendly"
                  // href={`https://calendly.com/reschedulings/${memo.invitee_uuid}`}
                >
                  Modifica
                </a>

                <a className="link-call-calendly">Vai alla call</a>
              </div>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
