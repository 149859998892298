import React from "react";
import "assets/components/footer-one-page.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

const FooterOnePage = (props) => {
  return (
    <>
      <div className="container-fluid px-0 bg-footer container-section-8">
        <div className={"box-footer"}>
          <div className="logo-footer"></div>
          <p className="mt-3 text-footer" style={{ color: "#ffffff" }}>
            Denuncia.io srl - Via del Lauro, 9 - 20121 Milano (MI) | P.IVA e CF:
            11929180963 |{" "}
            <a href="/privacy" className="link-footer" target="_blank">
              Privacy Policy
            </a>{" "}
            -{" "}
            <a href="/cookie-policy" className="link-footer" target="_blank">
              Cookie Policy
            </a>{" "}
            -{" "}
            <a href="/terms-conditions" className="link-footer" target="_blank">
              Termini e condizioni
            </a>
          </p>
          <p
            className="text-info-footer"
            style={{ color: "#ffffff", fontSize: "12px" }}
          >
            &copy; Denuncia.io S.r.l. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterOnePage;
