import React, { useState, useEffect } from "react";
import "assets/components/navbar.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import logo from "assets/img/denuncia.online_logo_con_lexia.png";
import { Offcanvas } from "bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const NavbarBlog = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isBlog, setIsBlog] = useState(false);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    if (location.pathname.startsWith("/b")) {
      setIsBlog(true);
    } else {
      setIsBlog(false);
    }

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, [location.pathname]);

  const handleLinkClick = (event) => {
    event.preventDefault();
    const targetSectionId = event.currentTarget
      .getAttribute("href")
      .substring(1);
    const targetSection = document.getElementById(targetSectionId);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setIsOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  const toggleOffcanvas = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      {/* navbar smart-tablet */}
      <nav
        id="main-navbar"
        className="navbar navbar-expand-lg bg-body-tertiary navbar-smart"
      >
        <div className="container-fluid mx-0">
          <a className="navbar-brand" href={isBlog ? "/" : "#"}>
            <img src={logo} width="150" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleOffcanvas}
            aria-controls="navbarNav"
            aria-expanded={isOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="bi bi-list icon-hamburger"></i>
              <i className="bi bi-list icon-hamburger"></i>
              <i className="bi bi-list icon-hamburger"></i>
            </span>
          </button>
          <div
            className={`offcanvas offcanvas-end${isOpen ? " show" : ""}`}
            tabIndex="-1"
            id="navbarNav"
            aria-labelledby="navbarNavLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="navbarNavLabel"></h5>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleOffcanvas}
                aria-label="Close"
              >
                <span className="navbar-toggler-icon">
                  <i className="bi bi-list icon-hamburger"></i>
                  <i className="bi bi-list icon-hamburger"></i>
                  <i className="bi bi-list icon-hamburger"></i>
                </span>
              </button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link text-link-dark"
                    aria-current="page"
                    href={isBlog ? "/" : "#denuncia-online"}
                    onClick={isBlog ? null : handleLinkClick}
                  >
                    Scopri di più
                  </a>
                </li>

                {props.isBlogDetail && (
                  <li className="nav-item">
                    <a
                      className="nav-link text-link-dark"
                      aria-current="page"
                      href="/b"
                    >
                      Blog
                    </a>
                  </li>
                )}

                <li
                  className="nav-item"
                  style={{ marginBottom: "20px !important" }}
                >
                  <a
                    type="button"
                    className="btn button-navbar text-light mt-1"
                    href="/login"
                  >
                    Accedi
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* pc  */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-pc">
        <div className="container-fluid mx-3">
          <a className="navbar-brand" href={isBlog ? "/" : "#"}>
            <img src={logo} width="150" alt="Logo" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="bi bi-list icon-hamburger"></i>
              <i className="bi bi-list icon-hamburger"></i>
              <i className="bi bi-list icon-hamburger"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto me-2">
              <li
                className="nav-item"
                style={{
                  marginRight: props.isBlogDetail === true ? "0px" : "50px",
                }}
              >
                <a
                  className="nav-link text-link-dark"
                  aria-current="page"
                  href={isBlog ? "/" : "#denuncia-online"}
                >
                  Scopri di più
                </a>
              </li>

              {props.isBlogDetail && (
                <li
                  className="nav-item"
                  style={{
                    marginRight: props.isBlogDetail === true ? "50px" : "0px",
                    marginLeft: "20px",
                  }}
                >
                  <a
                    className="nav-link text-link-dark"
                    aria-current="page"
                    href="/b"
                  >
                    Blog
                  </a>
                </li>
              )}

              <a
                type="button"
                className="btn button-navbar text-light mt-1"
                href="/login"
              >
                Accedi
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarBlog;
