import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";
import ReactHtmlParser from "react-html-parser";
import Lottie from "lottie-web";
import animationData from "assets/img/animation-blog.json";
import StarIcon from "@mui/icons-material/Star";
import "assets/components/BlogDetail.scss";
import { useParams } from "react-router-dom";

const BlogDetailArticle = (props) => {
  const [slugState, setSlugState] = useState("");
  const [selectedArticle, setSelectedArticle] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    const match = slug && slug.match(/(.+)/);
    const slugUrl = match ? match[0] : null;

    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + `/blog/article/slug/${slugUrl}`
        );

        if (!response.ok) {
          throw new Error("Errore nella richiesta API");
        }
        const data = await response.json();
        setSelectedArticle(data.data);
      } catch (error) {
        console.error("Errore durante il recupero dell'HTML:", error.message);
      }
    };

    fetchHtmlContent();
  }, [slug]);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  const slugAltImg = selectedArticle && selectedArticle.slug;
  const formattedAlt = slugAltImg ? slugAltImg.replace(/-/g, " ") : "";

  return (
    <>
      {selectedArticle ? (
        <Grid
          container
          className="Blog"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* SEZIONE ARTICOLO  */}
          <Grid
            item
            md={8}
            xs={10}
            style={{
              padding: "30px",
              textAlign: "center",
            }}
          >
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "36px",
                fontWeight: 600,
                color: "#1666b0",
                fontFamily: "Quicksand, sans-serif !important",
              }}
              className="textDescription"
            >
              {selectedArticle.title}
            </Typography>

            <Grid
              item
              xs={12}
              style={{
                width: "100%",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <div className="containerImage">
                <img
                  className="containerImageBlog"
                  src={
                    selectedArticle.featured_image
                      ? `data:image/png;base64,${selectedArticle.featured_image}`
                      : ""
                  }
                  alt={formattedAlt}
                />
              </div>
            </Grid>

            <Typography
              style={{
                marginTop: "50px",
                fontSize: "18px",
                textAlign: "start",
                color: "#20305b",
                fontFamily: "Quicksand, sans-serif !important",
              }}
              className="textDescription"
            >
              {ReactHtmlParser(selectedArticle.content)}
            </Typography>

            <Grid
              md={8}
              xs={10}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "0px",
              }}
            >
              <Link href={`/b`} size="small" style={{ display: "flex" }}>
                <KeyboardDoubleArrowLeftOutlined
                  style={{ fontSize: "12px", marginRight: "3px" }}
                />
                <Typography style={{ fontSize: "14px", marginTop: "-5px" }}>
                  Vedi altri contenuti
                </Typography>
              </Link>
            </Grid>
          </Grid>

          {/* CTA */}
          <Grid
            container
            style={{
              width: "100%",
              marginTop: "30px",
            }}
          >
            <Grid
              item
              xs={12}
              lg={11}
              className="bg-section"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={10} sm={3} style={{ display: "flex" }}>
                <div
                  id="lottie-animation-three"
                  className="container-animation-blog"
                ></div>
              </Grid>
              <Grid item xs={10} sm={8} style={{ display: "flex" }}>
                <div className="container-preFooter">
                  <Typography className="title-preFooter">
                    Vuoi fare una denuncia online ora?
                  </Typography>
                  <Typography className="text-preFooter">
                    DenunciaOnline è il primo servizio in Italia che ti permette
                    di presentare una denuncia ovunque ti trovi, direttamente
                    online.
                  </Typography>

                  <Grid item xs={10} className="container-icon-preFooter">
                    <Grid item xs={8} sm={3} className="containerSmallIcon">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{
                          marginLeft: "5px",
                        }}
                        className="text-icon-preFooter"
                      >
                        Bastano 2 min
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4} className="containerSmallIcon-2">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{
                          marginLeft: "5px",
                        }}
                        className="text-icon-preFooter"
                      >
                        Da PC o smartphone
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={4} className="containerSmallIcon-3">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{
                          marginLeft: "5px",
                        }}
                        className="text-icon-preFooter"
                      >
                        Modulo personalizzato
                      </Typography>
                    </Grid>
                  </Grid>

                  <div className="btn-preFooter">
                    <a href="/register" className="btn btn-section-5 mt-4">
                      Inizia subito
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default BlogDetailArticle;
