import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

const CookiePolicy = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://consent.cookiebot.com/e303bb34-462b-4481-b3b1-12eef42a050c/cd.js";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById("CookieDeclaration").appendChild(script);
  }, []);

  return (
    <>
      <main>
        <section className="p-5 process">
          <div className="container">
            <h2 className="my-5">Preferenze Cookies</h2>
            <p className="col fs-7">
              <script
                id="CookieDeclaration"
                src="https://consent.cookiebot.com/e303bb34-462b-4481-b3b1-12eef42a050c/cd.js"
                type="text/javascript"
                async
              ></script>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default CookiePolicy;
