import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "../assets/components/footer.scss";
import "assets/components/footer-one-page.scss";

const Footer = ({ children }) => {
  return (
    <Box
      className="Footer"
      component="footer"
      padding={1}
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Container maxWidth="lg" style={{ maxWidth: "1140px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              sx={{ marginTop: "6px", marginBottom: "6px" }}
              className="logowhite"
            >
              DenunciaOnline - ©2023 All Rights Reserved |{" "}
              <a href="/privacy" className="link-footer" target="_blank">
                Privacy Policy
              </a>{" "}
              -{" "}
              <a href="/cookie-policy" className="link-footer" target="_blank">
                Cookie Policy
              </a>{" "}
              -{" "}
              <a
                href="/terms-conditions"
                className="link-footer"
                target="_blank"
              >
                Termini e condizioni
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
