import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../_model/report/actions";
import { CircularProgress, Grid } from "@mui/material";
import { history } from "../../history";

const VerificationMail = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const verificationMail = useSelector((state) => state.report);

  const queryString = location.search.substring(1);

  const queryParams = queryString.split("&").reduce((acc, param) => {
    const [key, value] = param.split("=");
    acc[key] = value;
    return acc;
  }, {});

  const { token, email } = queryParams;

  const checkVerificationMail = () => {
    dispatch(actions.validationMail(token, email));
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (verificationMail.registered !== true) {
      await checkVerificationMail();
      // }

      setTimeout(() => {
        history.push("/login");
      }, 3000);
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (verificationMail.email_verification !== true) {
  //       await checkVerificationMail();
  //     }

  //     if (verificationMail.email_verification === true) {
  //       history.push("/login");
  //     }
  //   };

  //   fetchData();
  // }, [verificationMail]);

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10%",
        }}
      >
        <Grid item xs={12}>
          <CircularProgress style={{ color: "#fff" }} />
        </Grid>
      </Grid>
    </>
  );
};

export default VerificationMail;
