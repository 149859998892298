import {
  authHeader,
  authHeaderMultipart,
  authHeaderDownload,
} from "../../helpers";
import handleResponse from "../handleResponse";

const endpoint = process.env.REACT_APP_API_ENDPOINT + `action/`;
const endpointDownload = process.env.REACT_APP_API_ENDPOINT + `action/download`;

export const service = {
  doAction,
  upload,
  uploadIdDocument,
  download,
};

function doAction(data) {
  let method = "POST";
  const requestOptions = {
    method: method,
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(endpoint, requestOptions).then(handleResponse);
}

function upload(data) {
  const formData = new FormData();

  formData.append("action", data.action);
  formData.append("reportId", data.reportId);
  formData.append("file", data.file);
  formData.append("fileName", data.fileName);

  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: formData,
    redirect: "follow",
  };

  return fetch(endpoint, requestOptions).then(handleResponse);
}

function uploadIdDocument(data) {
  const formData = new FormData();

  formData.append("action", data.action);
  formData.append("reportId", data.reportId);

  for (var i = 0; i < data.files.length; i++) {
    formData.append("files[]", data.files[i]);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeaderMultipart(false),
    body: formData,
    redirect: "follow",
  };

  return fetch(endpoint, requestOptions).then(handleResponse);
}

function download(data) {
  let method = "POST";
  const requestOptions = {
    method: method,
    //url: endpointDownload + '/' + data.reportId + '/' + data.documentType,
    url: endpointDownload,
    headers: authHeaderDownload(false),
    responseType: "blob",
    //responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
    type: "application/pdf",
    body: JSON.stringify(data),
  };

  return fetch(endpointDownload, requestOptions).then(handleResponse);
}
