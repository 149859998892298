import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../../assets/components/Terms.scss";

const TermsConditionsComponent = (props) => {
  return (
    <>
      <main className="Terms">
        <section className="p-5 process ">
          <div className="container">
            <h2 className="my-5">
              Condizioni generali di utilizzo della Piattaforma
            </h2>
            <p className="col fs-7">
              <ol className="terms">
                <li className="text-term">
                  {" "}
                  <strong>1. Premesse e definizioni.</strong>
                  <ol>
                    <li>
                      {" "}
                      Le presenti condizioni generali di utilizzo (le
                      “Condizioni Generali”) sono volte a disciplinare
                      l’utilizzo da parte dell’utente (l’“Utente”) del sito
                      internet <a href="/">www.denunciaonline.eu</a> (la
                      “Piattaforma”) sviluppata e gestita da Denuncia.io S.r.l.,
                      con sede legale in Milano (MI), Via dell’Annunciata 23/4,
                      codice fiscale, partita IVA e numero di iscrizione al
                      Registro Imprese di Milano Monza Brianza Lodi n.
                      11929180963 (di seguito, “Denuncia” o la “Società”) nonché
                      l’acquisto tramite la Piattaforma dei Servizi offerti
                      dalla Società come descritti al successivo articolo 2.
                    </li>
                    <li>
                      {" "}
                      L’accesso alla Piattaforma e/o la fruizione dei Servizi
                      messi a disposizione da Denuncia per il tramite della
                      Piattaforma o in relazione alla stessa sono subordinati al
                      rispetto e all’accettazione, da parte dell’Utente, delle
                      presenti Condizioni Generali e di qualsiasi ulteriore
                      addendum, integrazione contrattuale, comunicazione o
                      notifica che dovessero essere richiesti, trasmessi o messi
                      a disposizione da Denuncia a propria discrezione.
                      Accedendo alla Piattaforma e utilizzando i Servizi,
                      l’Utente riconosce di aver letto attentamente e compreso
                      pienamente i termini riportati nelle presenti Condizioni
                      Generali e accetta di essere vincolato alle stesse. Salvo
                      per i casi in cui sia richiesta all’Utente la
                      manifestazione di un consenso esplicito, con
                      l’accettazione della presente Condizioni Generali e
                      l’utilizzo dei Servizi l’Utente accetta il trattamento dei
                      propri dati personali da parte di Denuncia ai sensi
                      dell’informativa in materia di trattamento dei dati
                      personali (l’“Informativa Privacy”) consultabile al
                      seguente indirizzo:{" "}
                      <a href="/privacy">www.denunciaonline.eu/privacy</a>. La
                      mancata accettazione delle presenti Condizioni Generali
                      comporta l’impossibilità di accedere alla Piattaforma e di
                      fruire dei Servizi.
                    </li>
                    <li>
                      {" "}
                      Lo status di Utente è acquisito una volta completato il
                      processo di registrazione alla Piattaforma, nel rispetto
                      della procedura a tal fine prevista e dopo aver fornito le
                      informazioni appositamente richieste dalla Piattaforma. La
                      Società si riserva il diritto di non accettare, a proprio
                      insindacabile giudizio, le richieste di registrazione
                      presentate tramite la Piattaforma, nonché di aggiornare
                      e/o modificare, di volta in volta, i requisiti per
                      l’adesione alla Piattaforma e/o per il mantenimento dello
                      status di Utente, senza alcun obbligo di informativa
                      preventiva o successiva nei confronti dell’Utente stesso.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>2. Oggetto ed esecuzione dei Servizi.</strong>
                  <ol>
                    <li>
                      {" "}
                      La Società mette a disposizione dell’Utente, attraverso la
                      Piattaforma, un servizio di compilazione e redazione
                      automatizzata di denunce di reati (le “Denunce”) e di mero
                      deposito delle stesse, nonché ulteriori servizi di volta
                      in volta pubblicizzati e/o offerti sulla Piattaforma (di
                      seguito, i “Servizi”). In nessun caso è prevista ulteriore
                      assistenza relativa all'eventuale instaurazione di un
                      procedimento penale connesso alle denunce presentate.
                    </li>

                    <li>
                      {" "}
                      La Società ha la facoltà di avvalersi, sotto la propria
                      completa responsabilità, della collaborazione di uno o più
                      avvocati per l’esecuzione dei Servizi (il
                      “Professionista”). Il Professionista presenterà le Denunce
                      alle autorità competenti per conto dell’Utente, senza
                      effettuare alcuna verifica e/o controllo delle stesse. Il
                      Professionista svolgerà tale attività senza ricevere il
                      conferimento alcuno di incarichi professionali una tantum,
                      continuativi e/o di natura intellettuale.
                    </li>

                    <li>
                      La Società mette altresì a disposizione dell’Utente un
                      servizio di consulenza legale (il “Servizio di
                      consulenza”) con un Professionista, accessibile prenotando
                      un appuntamento attraverso l’apposito tool disponibile
                      sulla Piattaforma. Il pagamento del Servizio di consulenza
                      avverrà secondo le modalità disciplinate al successivo
                      articolo 7.
                    </li>

                    <li>
                      {" "}
                      In relazione alla compilazione e redazione automatizzata
                      delle Denunce, la Società non offre alcuna consulenza e/o
                      parere legale in relazione alle esigenze espresse
                      dall’Utente. Qualsiasi spiegazione e/o informazione che
                      sia messa a disposizione dalla Piattaforma nell'ambito
                      della compilazione e redazione automatizzata delle Denunce
                      non è da intendersi in nessun caso alla stregua di una
                      raccomandazione personalizzata o consulenza legale.
                    </li>

                    <li>
                      {" "}
                      La Società si riserva il diritto di nominare e/o avvalersi
                      dei servizi offerti da soggetti terzi per l’esecuzione dei
                      Servizi, ivi incluse l’attività di fornitura di
                      certificati di firma digitale necessari ai fini della
                      presentazione delle Denunce presso le Autorità competenti.
                      Il rilascio dei certificati di firma digitale viene
                      effettuato tramite l'apposito servizio fornito da Yousign
                      SAS, accessibile tramite la Piattaforma, le cui condizioni
                      di utilizzo sono consultabili al seguente indirizzo:{" "}
                      <a
                        href="https://yousign.com/it-it/termini-e-condizioni"
                        target="_blank"
                      >
                        www.yousign.com/it-it/termini-e-condizioni
                      </a>
                      .
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>
                    3. Registrazione alla Piattaforma, creazione dell’Account e
                    sicurezza.
                  </strong>
                  <ol>
                    <li>
                      {" "}
                      Creazione dell’Account
                      <ol>
                        <li>
                          {" "}
                          Al fine di usufruire dei Servizi, l’Utente dovrà
                          preliminarmente registrarsi alla Piattaforma creando
                          un account nella sezione dedicata della Piattaforma,
                          fornendo tutte le informazioni personali richieste
                          tempo per tempo dalla Società (nome, cognome, email)
                          (l’“Account”). La Società non è responsabile della
                          veridicità e della correttezza delle informazioni
                          fornite dall’Utente.
                        </li>
                        <li>
                          {" "}
                          Al fine di accedere all’Account, l’Utente dovrà
                          utilizzare delle credenziali di accesso, composte da
                          (i) un codice di identificazione cliente (e-mail)
                          indicato dall‘Utente in fase di registrazione alla
                          Piattaforma, (ii) una parola chiave (password) scelta
                          dall’Utente, e (iii) ove richiesto in considerazione
                          delle funzionalità utilizzate dall’Utente, una
                          componente di identificazione a due fattori (le
                          “Credenziali di Accesso”).
                        </li>
                        <li>
                          {" "}
                          Resta inteso che la Società potrà rifiutare la
                          richiesta di iscrizione alla Piattaforma a proprio
                          insindacabile giudizio e senza obbligo di motivazione,
                          ogni qual volta ciò si rende necessario in
                          ottemperanza di obblighi di legge, regolamenti o
                          provvedimenti dell’Autorità.
                        </li>
                      </ol>
                    </li>
                    <li>
                      {" "}
                      Accesso all’Account e responsabilità
                      <ol>
                        <li>
                          {" "}
                          L’Utente è responsabile della completezza, veridicità
                          e correttezza delle informazioni fornite alla Società
                          nel contesto della procedura di registrazione
                          finalizzata alla creazione dell’Account, obbligandosi
                          a comunicare prontamente a Denuncia ogni modifica di
                          tali dati, informazioni e documenti forniti in sede di
                          registrazione o successivamente, o comunque a
                          disposizione della Società in relazione al proprio
                          Account.{" "}
                        </li>
                        <li>
                          {" "}
                          Ad esito della procedura di registrazione, Denuncia
                          invierà all’Utente, all’indirizzo e-mail comunicato in
                          sede di registrazione, una comunicazione di conferma
                          della registrazione.
                        </li>
                        <li>
                          {" "}
                          Le Credenziali di Accesso devono essere conservate
                          dall’Utente utilizzando la massima riservatezza e
                          diligenza, con estrema cura e attenzione, in modo tale
                          da impedirne l’utilizzo indebito da parte di terzi.
                          Esse possono essere utilizzare solo dall’Utente e non
                          possono essere cedute a terzi. L’Utente è l’unico ed
                          esclusivo responsabile della custodia delle
                          Credenziali di Accesso e si impegna a mantenerle
                          segrete e ad assicurarsi che nessun terzo vi abbia
                          accesso. Egli si impegna inoltre a informare
                          immediatamente la Società in caso di furto,
                          smarrimento, sottrazione delle Credenziali di Accesso,
                          ovvero nel caso in cui sospetti o venga a conoscenza
                          di un uso indebito o di una indebita divulgazione
                          delle stesse. L’Utente sarà il solo responsabile di
                          qualsiasi danno arrecato a se stesso e/o a terzi in
                          caso di accesso alle Credenziali di Accesso e/o
                          indebito utilizzo delle stesse da parte di terzi.
                        </li>
                        <li>
                          {" "}
                          Al fine della creazione dell’Account, l’Utente deve
                          aver compiuto il diciottesimo anno di età. In caso di
                          mancato rispetto da parte dell’Utente del requisito
                          della maggiore età, la Piattaforma impedirà all’Utente
                          di registrare il proprio Account.
                        </li>
                        <li>
                          {" "}
                          La Società si riserva di verificare di volta in volta
                          i dati, le informazioni e i documenti forniti
                          dall’Utente in fase di registrazione, di chiederne
                          periodicamente l’aggiornamento e/o di richiedere la
                          trasmissione di dati, informazioni o documenti
                          aggiuntivi, anche in base a quanto richiesto dalla
                          normativa applicabile. La Società potrà, a proprio
                          insindacabile giudizio, disporre la sospensione e/o
                          l’interruzione dei Servizi offerti e/o dell’accesso
                          alla Piattaforma in caso di rifiuto di collaborare da
                          parte dell’Utente, ovvero laddove quest’ultimo non
                          fornisca informazioni, dati o documenti completi,
                          corretti e veritieri secondo i tempi richiesti dalla
                          Società stessa.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>4. Diritto di recesso e rimborso.</strong>
                  <ol>
                    <li>
                      {" "}
                      L’Utente rinuncia espressamente alla facoltà di esercitare
                      il proprio diritto di recesso con riferimento ai Servizi
                      offerti sulla Piattaforma ai sensi dell’Articolo 59, par
                      1, lett. o) del D. Lgs. 206/2005.{" "}
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>
                    5. Cancellazione dell’Account da parte dell’Utente.
                  </strong>
                  <ol>
                    <li>
                      {" "}
                      L’Utente potrà domandare in ogni momento la disattivazione
                      del proprio account inviando una comunicazione scritta via
                      e-mail al seguente indirizzo: info@denunciaonline.eu.
                    </li>
                    <li>
                      {" "}
                      A seguito della trasmissione della richiesta di
                      cancellazione, l’Account dell’Utente sarà cancellato dalla
                      Piattaforma entro 10 (dieci) giorni lavorativi. In tal
                      caso, i dati personali forniti dall’Utente saranno
                      cancellati ai sensi dell’informativa privacy di Denuncia
                      consultabile al seguente indirizzo:{" "}
                      <a href="/privacy">www.denunciaonline.eu/privacy</a>.
                    </li>
                    <li>
                      {" "}
                      A seguito della cancellazione dell’Account, l’Utente non
                      potrà più utilizzare i Servizi offerti tramite la
                      Piattaforma nonché visualizzare lo storico dei Servizi
                      acquistati tramite il proprio Account.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>
                    6. Sospensione e Cancellazione dell’Account da parte di
                    Denuncia.
                  </strong>
                  <ol>
                    <li>
                      {" "}
                      In aggiunta alle ipotesi espressamente contemplate dalle
                      altre previsioni delle presenti Condizioni Generali, la
                      Società si riserva in ogni caso la facoltà di sospendere,
                      bloccare l’accesso all’Account e/o di cancellare lo
                      stesso, interrompendo la prestazione dei Servizi, a
                      propria discrezione, con effetto immediato, previa
                      comunicazione scritta via e-mail:
                      <ol>
                        <li>
                          a. qualora l’Utente non abbia effettuato l’accesso
                          all’Account per un periodo pari o superiore a 12
                          (dodici) mesi consecutivi;
                        </li>
                        <li>
                          b. qualora l’Utente abbia fornito informazioni non
                          veritiere durante la registrazione per la creazione
                          del proprio Account ovvero successivamente nell’ambito
                          del proprio rapporto con la Società;
                        </li>
                        <li>
                          c. qualora l’Utente si rifiuti di fornire informazioni
                          relative al proprio Account e/o alla propria identità,
                          se del caso su richieste dell’autorità giudiziaria,
                          delle autorità di ordine pubblico e di qualsiasi altra
                          autorità che ne abbia titolo ai sensi delle leggi
                          applicabili;
                        </li>
                        <li>
                          d. in caso di violazione da parte dell’Utente delle
                          disposizioni di cui alle presenti Condizioni Generali
                          o di legge;
                        </li>
                        <li>
                          e. in caso di utilizzo fraudolento o illecito, anche
                          solo sospetto, della Piattaforma;
                        </li>
                        <li>
                          f. al fine di proteggere l’Utente e/o la Piattaforma
                          da furti di identità e/o altre attività fraudolente
                          dell’Utente e/o di terze parti;
                        </li>
                        <li>
                          g. qualora venga così richiesto dall’autorità
                          giudiziaria, dalle autorità di ordine pubblico e da
                          qualsiasi altra autorità che ne abbia titolo ai sensi
                          delle leggi applicabili.
                        </li>
                      </ol>
                    </li>
                    <li>
                      {" "}
                      In caso di sospensione, la Società potrà decidere – a
                      propria discrezione e insindacabile giudizio – se
                      riattivare o cancellare permanentemente l’Account
                      dell’Utente. Per procedere alla riattivazione dell’Account
                      sospeso, l’Utente deve farne richiesta esplicita alla
                      Società inviando apposita comunicazione come previsto ai
                      sensi dell’articolo 12 che segue.
                    </li>
                    <li>
                      {" "}
                      In caso di cancellazione dell’Account, l’Utente non potrà
                      più utilizzare i Servizi offerti tramite la Piattaforma
                      nonché visualizzare lo storico dei Servizi acquistati
                      tramite il proprio Account.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>7. Corrispettivo e modalità di pagamento.</strong>
                  <ol>
                    <li>
                      {" "}
                      Il corrispettivo dovuto dall’Utente alla Società per i
                      Servizi è indicato tramite la Piattaforma al momento di
                      acquisto del Servizio (il “Corrispettivo”).{" "}
                    </li>

                    <li>
                      {" "}
                      Affinché l’Utente possa usufruire dei Servizi, il
                      pagamento del Corrispettivo dovrà essere effettuato
                      tramite PayPal (Europe) S.à.r.l. et Cie, S.C.A.(“Paypal”)
                      le cui condizioni di utilizzo sono consultabili al
                      seguente indirizzo:{" "}
                      <a
                        href="https://www.paypal.com/it/webapps/mpp/ua/useragreement-full"
                        target="_blank"
                      >
                        www.paypal.com/it/webapps/mpp/ua/useragreement-full
                      </a>
                      . Al fine di completare il pagamento, l’Utente verrà
                      reindirizzato alla pagina di log-in del sito PayPal dove
                      inserire l’indirizzo e-mail personale e password del
                      proprio conto PayPal, oppure accedere alla procedura per
                      la creazione di uno nuovo (il “Link di Pagamento”). Il
                      Corrispettivo dovuto per il Servizio viene addebitato sul
                      conto PayPal dall’Utente. Per ogni transazione eseguita
                      con il conto PayPal, l’Utente riceverà un email di
                      conferma da PayPal.
                    </li>

                    <li>
                      {" "}
                      L’Utente che voglia usufruire del Servizio di consulenza,
                      effettuerà il pagamento del relativo Corrispettivo tramite
                      il tool Calendly, accessibile tramite la Piattaforma, le
                      cui condizioni di utilizzo sono consultabili al seguente
                      indirizzo:{" "}
                      <a href=" https://calendly.com/terms" target="_blank">
                        https://calendly.com/terms.
                      </a>{" "}
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>8. Contenuti inviati dagli Utenti.</strong>
                  <ol>
                    <li>
                      {" "}
                      L’Utente potrà caricare contenuti sulla Piattaforma per le
                      sole finalità connesse ai Servizi, purché tali contenuti
                      non abbiano carattere illecito, ingannevole, contrario al
                      buon costume (ad es. aventi contenuto osceno,
                      intimidatorio, diffamatorio, pornografico, abusivo, ovvero
                      che a qualsiasi titolo violino la privacy, i diritti di
                      proprietà intellettuale e/o industriale di Denuncia e/o di
                      terzi), ovvero che non risultino lesivi dei diritti o
                      della reputazione di Denuncia e/o di terzi e non
                      contengano virus, propaganda politica e sollecitazione
                      commerciale. L’Utente si impegna a tenere manlevata e
                      indenne Denuncia da qualsiasi danno, perdita o spesa sorta
                      in conseguenza a qualsiasi forma di contestazione da parte
                      di terzi relativamente a tali contenuti caricati sulla
                      Piattaforma.
                    </li>
                    <li>
                      {" "}
                      È fatto divieto all’Utente di utilizzare indirizzi di
                      posta elettronica nonché dati personali e Credenziali di
                      Accesso di titolarità di altri Utenti.
                    </li>
                    <li>
                      {" "}
                      Denuncia si riserva il diritto di eseguire controlli sui
                      contenuti caricati dall’Utente, e dunque potrà in ogni
                      momento cancellare, spostare, modificare i contenuti che,
                      a suo insindacabile giudizio, possano apparire illeciti,
                      abusivi, diffamatori, osceni o lesivi di qualsivoglia
                      diritto.
                    </li>
                    <li>
                      {" "}
                      L’Utente concede a Denuncia, al fine di erogare i Servizi,
                      un diritto d’uso non esclusivo sui contenuti caricati,
                      senza limitazioni di aree geografiche. Entro i suddetti
                      limiti, Denuncia potrà pertanto, direttamente o tramite
                      terzi vincolati da obblighi di confidenzialità, utilizzare
                      ogni contenuto inviato dall’Utente.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>9. Garanzie e limitazioni di responsabilità.</strong>
                  <ol>
                    <li>
                      {" "}
                      L’Utente dichiara e garantisce di:
                      <ol>
                        <li>
                          {" "}
                          utilizzare la Piattaforma e/o i Servizi, in conformità
                          con le previsioni delle presenti Condizioni Generali,
                          alla legge, al buon costume, all’ordine pubblico e
                          secondo quanto previsto dalle presenti Condizioni
                          Generali. Alla stessa maniera, l’Utente si impegna a
                          fare un uso appropriato dei Servizi e/o dei contenuti
                          della Piattaforma, non impiegandoli per attività
                          illecite, contrarie alla buona fede o al buon costume
                          o delittuose, e a non violare diritti di terzi;
                        </li>
                        <li>
                          {" "}
                          utilizzare la Piattaforma sotto la propria esclusiva
                          responsabilità, in conformità e nel rispetto delle
                          caratteristiche tecniche e delle funzionalità della
                          Piattaforma stessa nonché, se del caso, delle
                          istruzioni e indicazioni di volta in volta impartite
                          dalla Società;
                        </li>
                        <li>
                          {" "}
                          assumersi la piena responsabilità delle informazioni e
                          del materiale forniti. In particolare, quest’ultimo si
                          impegna a non trasmettere, introdurre, distribuire e/o
                          rendere disponibile a terzi qualsiasi materiale e/o
                          informazione (a titolo esemplificativo: dati,
                          messaggi, immagini, file audio, fotografie etc..) che
                          sia in contrasto con la morale, l’ordine pubblico, le
                          presenti Condizioni Generali e, in generale, con ogni
                          disposizione di legge e/o regolamento applicabile. È
                          proibito all’Utente l’uso della Piattaforma e dei
                          Servizi forniti per scopi diversi da quelli previsti
                          nelle presenti Condizioni Generali;
                        </li>
                        <li>
                          {" "}
                          astenersi dall’assumere qualsiasi condotta o dal
                          compiere qualsiasi operazione che causi o possa
                          causare danni alla Piattaforma o che possa
                          comprometterne le prestazioni, la disponibilità e/o
                          l’accessibilità. La Piattaforma dovrà essere in ogni
                          caso utilizzata in modo lecito, senza porre in essere
                          alcuna attività di carattere fraudolenta nei confronti
                          della Società, di altri Utenti e/o di soggetti terzi;
                        </li>
                        <li>
                          {" "}
                          utilizzare l’Account soltanto a titolo personale e non
                          per conto terzi. Le Credenziali di Accesso potranno
                          essere utilizzate esclusivamente dall’Utente e non
                          saranno da quest’ultimo cedute o rivelate in alcun
                          modo a soggetti terzi.
                        </li>
                      </ol>
                    </li>
                    <li>
                      {" "}
                      L’Utente prende atto ed accetta che Denuncia avrà il
                      diritto di sospendere o interrompere l’utilizzo della
                      Piattaforma e dei Servizi e/o procedere alla cancellazione
                      dell’Account in qualsiasi momento in caso di mancato
                      rispetto degli obblighi sopra elencati da parte
                      dell’Utente. L’Utente si farà inoltre carico, in via
                      esclusiva, di qualsivoglia perdita o danno che dovesse
                      derivare dall’inadempimento di detti obblighi e si impegna
                      a tenere la Società manlevata e indenne in caso di
                      eventuali azioni, pretese, costi, perdite o altre
                      conseguenze sfavorevoli derivanti da tale inadempimento.{" "}
                    </li>
                    <li>
                      {" "}
                      L’Utente riconosce e accetta espressamente che:
                      <ol>
                        <li>
                          {" "}
                          la Società non fornisce alcuna garanzia in merito alla
                          qualità delle funzionalità della Piattaforma e/o dei
                          Servizi. In particolare, la Società non garantisce che
                          la Piattaforma e/o i Servizi soddisfino le necessità
                          del Cliente e/o siano disponibili in modo
                          ininterrotto, sicuro e privo di errori;
                        </li>
                        <li>
                          {" "}
                          in nessun caso la Società e/o il Professionista di cui
                          si avvale per l’erogazione dei Servizi saranno
                          responsabili nei confronti dell’Utente o di terzi con
                          riferimento a qualsiasi illecito, negligenza,
                          responsabilità oggettiva in relazione a qualsiasi
                          perdita di profitto, dati persi o danneggiati, guasti
                          o malfunzionamenti del computer, interruzione
                          dell’attività, o altri danni speciali, indiretti,
                          incidentali o consequenziali di qualsiasi tipo
                          derivanti dall’uso o dall’impossibilità di utilizzare
                          la Piattaforma e/o i Servizi, anche qualora l’Utente
                          sia stato avvisato della possibilità di tali perdite o
                          danni e se tali perdite o danni erano prevedibili;
                        </li>
                        <li>
                          {" "}
                          in nessun caso la Società è responsabile e potrà
                          essere ritenuta responsabile del corretto
                          funzionamento del Link di Pagamento e/o di qualsiasi
                          danno derivante dall’utilizzo da parte dell’Utente di
                          PayPal e/o altro servizio di pagamento in caso di
                          malfunzionamento degli stessi per causa di terze
                          parti;
                        </li>
                        <li>
                          {" "}
                          la Società non avrà alcuna responsabilità per
                          qualsiasi uso illegittimo della Piattaforma e/o dei
                          Servizi da parte dell’Utente. Quest’ultimo dovrà
                          manlevare e tenere indenne la Società da qualsiasi
                          reclamo, danno, responsabilità, costo e spesa (incluse
                          le ragionevoli spese legali) derivanti dall’utilizzo
                          della Piattaforma e/o dei Servizi da parte dell’Utente
                          in violazione delle previsioni delle presenti
                          Condizioni Generali;
                        </li>
                        <li>
                          {" "}
                          la Società potrà modificare, limitare o rimuovere
                          tutte o alcune funzionalità della Piattaforma e/o i
                          Servizi per motivi di sicurezza dei dati, necessità
                          tecniche o a causa di modifiche della legge
                          applicabile, a condizione che la modifica, la
                          limitazione o la rimozione sia ragionevole per
                          l’Utente in considerazione degli interessi della
                          Società e dell’Utente. La Società informerà l’Utente
                          di tali modifiche in tempo utile e per iscritto
                          tramite mail;
                        </li>
                        <li>
                          {" "}
                          Denuncia non fornisce alcuna garanzia circa la
                          disponibilità e la velocità dei propri Servizi,
                          compresa la Piattaforma, l’evasione delle richieste
                          impartite dall’Utente e la disponibilità delle diverse
                          funzionalità fornite tramite la Piattaforma, anche in
                          considerazione del fatto che i tempi tecnici per
                          l’evasione completa dei Servizi dipendono dalla
                          rapidità dei soggetti esterni necessariamente
                          coinvolti (es. Procura o enti certificatori per il
                          rilascio di certificati di firma digitale).
                        </li>
                        <li>
                          {" "}
                          Denuncia non sarà in nessun caso responsabile del
                          malfunzionamento della Piattaforma o dei Servizi
                          offerti, qualora ciò dipenda da cause impreviste ed
                          imprevedibili, di forza maggiore o dipendenti da
                          condotte di soggetti terzi. La Società sarà esente da
                          qualsiasi responsabilità per i vizi originari o
                          sopravvenuti della Piattaforma.{" "}
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>10. Obblighi di riservatezza.</strong>
                  <ol>
                    <li>
                      {" "}
                      Tutte le informazioni scambiate tra l’Utente e la Società
                      ai sensi delle presenti Condizioni Generali saranno
                      considerate strettamente confidenziali (le “Informazioni
                      Riservate”) e saranno utilizzate esclusivamente per la
                      fornitura dei Servizi.
                    </li>
                    <li> La Società si impegna a:</li>
                    <li>
                      {" "}
                      divulgare le Informazioni Riservate solo ai suoi
                      rappresentanti che abbiano necessità di conoscerle, a
                      condizione che tali rappresentanti siano vincolati da
                      obblighi di non divulgazione;{" "}
                    </li>
                    <li>
                      {" "}
                      proteggere le Informazioni Riservate dalla divulgazione
                      nello stesso modo e con lo stesso dovere di diligenza che
                      l’Utente utilizza per proteggere le proprie Informazioni
                      Riservate di pari importanza; e{" "}
                    </li>
                    <li>
                      {" "}
                      distruggere le Informazioni Riservate su richiesta
                      dell’Utente parte, ad eccezione di qualsiasi parte che
                      debba essere conservata per legge.
                    </li>
                    <li>
                      {" "}
                      Nel caso in cui una parte sia tenuta a divulgare una
                      qualsiasi delle Informazioni Riservate ai sensi di legge,
                      se consentito dalla legge applicabile, dovrà prontamente
                      notificare all’altra parte i termini e le circostanze di
                      tale richiesta.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>
                    11. Diritti di proprietà industriale e intellettuale.
                  </strong>
                  <ol>
                    <li>
                      {" "}
                      Denuncia dichiara di essere titolare di tutti i diritti di
                      proprietà intellettuale e industriale relativi e/o
                      afferenti alla Piattaforma nonché ai contenuti ai quali è
                      possibile accedere tramite la medesima. Pertanto, tutti i
                      marchi, figurativi o nominativi e tutti gli altri segni,
                      nomi commerciali, marchi di servizio, marchi denominativi,
                      denominazioni commerciali, illustrazioni, immagini, loghi,
                      contenuti, le immagini presenti sulla Piattaforma, nonché
                      il design grafico e la struttura di navigazione della
                      stessa sono e rimangono di titolarità di Denuncia, che
                      detiene il diritto esclusivo di esercitare i diritti di
                      sfruttamento in qualsiasi modo, in particolare i diritti
                      di riproduzione, distribuzione, pubblicazione e
                      trasformazione, in conformità con la legge italiana in
                      materia di diritti di proprietà intellettuale e
                      industriale.
                    </li>
                    <li>
                      {" "}
                      Le presenti Condizioni Generali non concedono all’Utente
                      alcuna licenza d’uso relativa alla Piattaforma e/o a
                      singoli contenuti ivi disponibili, se non diversamente
                      disciplinato.
                    </li>
                    <li>
                      {" "}
                      L’autorizzazione ad accedere alla Piattaforma garantita da
                      Denuncia all’Utente dalla data di attivazione
                      dell’Account, non può essere interpretata quale rinuncia,
                      cessione, concessione di licenza o in ogni caso
                      assegnazione anche parziale da parte di Denuncia
                      all’Utente di qualsiasi diritto di proprietà intellettuale
                      o industriale.{" "}
                    </li>
                    <li>
                      {" "}
                      L’Utente non potrà modificare, copiare, riutilizzare,
                      sfruttare, riprodurre, pubblicizzare, inviare per posta,
                      trasmettere, l’elaborare o distribuire in qualsivoglia
                      modo di tutti o di parte dei contenuti della Piattaforma
                      anche a fini pubblicitari e/o commerciali. Eventuali
                      riproduzioni dei contenuti della Piattaforma saranno
                      considerate violazioni del diritto proprietà intellettuale
                      ed industriale di Denuncia.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>12. Comunicazioni.</strong>
                  <ol>
                    <li>
                      {" "}
                      Per qualsiasi comunicazione, contestazione, richiesta o
                      reclamo relativo alla Piattaforma e/o ai Servizi prestati
                      dalla Società l’Utente può utilizzare il seguente
                      indirizzo e-mail: info@denunciaonline.eu.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>13. Legge applicabile e foro competente.</strong>
                  <ol>
                    <li>
                      {" "}
                      Le presenti Condizioni Generali sono disciplinate e
                      dovranno essere interpretate in base alla legge italiana.
                    </li>
                    <li>
                      {" "}
                      Le eventuali controversie relative alle Condizioni
                      Generali saranno devolute alla giurisdizione esclusiva del
                      foro di Milano.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>14. Rinvio a norme di legge.</strong>
                  <ol>
                    <li>
                      {" "}
                      Per quanto non previsto dalle presenti Condizioni
                      Generali, saranno applicabili le norme di legge italiana
                      vigenti.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>15. Trattamento dei dati personali.</strong>
                  <ol>
                    <li>
                      {" "}
                      La Società si impegna ad applicare misure tecniche ed
                      organizzative adeguate a garantire l’adempimento puntuale
                      ed esatto di tutti gli obblighi vigenti in materia di
                      privacy e trattamento dei dati personali, secondo quanto
                      prescritto dal Regolamento UE 2016/679 sulla protezione
                      dei dati (“GDPR”), nonché da ogni altra normativa anche
                      nazionale applicabile.
                    </li>
                    <li>
                      {" "}
                      La Società si configura quale titolare del trattamento ai
                      sensi dell’Articolo 24 del GDPR in relazione al
                      trattamento dei dati personali dell’Utente; tale
                      trattamento avverrà in conformità a quanto previsto
                      nell’Informativa Privacy consultabile al seguente
                      indirizzo:{" "}
                      <a href="/privacy">www.denunciaonline.eu/privacy</a>.
                    </li>
                  </ol>
                </li>

                <li className="text-term">
                  {" "}
                  <strong>16. Modifica delle condizioni generali.</strong>
                  <ol>
                    <li>
                      {" "}
                      La Società si riserva il diritto di modificare
                      unilateralmente le presenti Condizioni Generali in
                      qualsiasi momento, anche al fine di adattarle a modifiche
                      legislative o a eventuali modifiche ai Servizi stessi.
                    </li>
                    <li>
                      {" "}
                      Le modifiche di cui sopra, consultabili alla pagina
                      Condizioni Generali al seguente indirizzo:{" "}
                      <a href="/terms-conditions">
                        www.denunciaonline.eu/terms-conditions
                      </a>
                      , saranno notificate all’Utente laddove le stesse
                      conseguano a modifiche dei Servizi.
                    </li>
                  </ol>
                </li>
              </ol>
            </p>
            <br />
            <br />
            <br />
            <br />
            <p>Ultimo aggiornamento: 28/11/2023</p>
          </div>
        </section>
      </main>
    </>
  );
};

export default TermsConditionsComponent;
