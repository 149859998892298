import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import logo from "assets/img/denuncia.online_logo_con_lexia.png";

const HeaderLogo = (props) => {
  return (
    <>
      <header className="">
        <nav className="navbar navbar-light">
          <div className="px-4 mt-3 text-left container">
            <a href="/">
              <img src={logo} width="200" />
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderLogo;
