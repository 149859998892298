import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '_model';


export default function configureStore() {

  const loadState = () => {
    try {
      const serializedState = localStorage.getItem('report_state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('report_state', serializedState);
    } catch {
      // ignore write errors
    }
  };

  const persistedState = loadState();

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
    )
  );

  const store = createStore(
    rootReducer,
    persistedState,
    enhancer
  );

  store.subscribe(() => {
    saveState(store.getState());

  });



  return store;
}

