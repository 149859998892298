import { red } from '@mui/material/colors';
import React from 'react';



const EnvInfo = () => {

  if (process.env.REACT_APP_ENVIRONMEN !== 'LIVE') {
    return <div style={{
      backgroundColor: "red",
      padding: "10px",
      color: "white",
      textAlign: "center",
      fontWeight: "bold",
    }}>
      <div>{process.env.REACT_APP_ENVIRONMEN} - {process.env.REACT_APP_API_ENDPOINT}</div>
    </div>;
  }

  return null;


};



export default EnvInfo;
