import { Button, Typography } from "@mui/material";
import React from "react";
import "../assets/components/button-primary.scss";
import { ZnButton } from "./znFields/ZnButton";

const ButtonPrimary = (props) => {
  const { onClick = () => {}, disabled = false } = props; // Aggiungi la prop "disabled"

  return (
    <div
      className={props.consulenza ? "ButtonPrimaryConsulenza" : "ButtonPrimary"}
    >
      <ZnButton
        disableElevation
        {...props}
        fullWidth
        size="large"
        color="primary"
        variant="contained"
        confirmYes="Conferma"
        confirmNo="Rileggi"
        onClick={onClick}
        type="submit"
        checkbox={true}
        label="Autorizzo l'invio della denuncia e dei dati in essa contenuti agli enti competenti e sono consapevole che la nomina del difensore deve intendersi limitata al solo deposito della denuncia presso la procura competente."
        disabled={disabled}
      >
        {props.children}
      </ZnButton>
    </div>
  );
};

export default ButtonPrimary;
