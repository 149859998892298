import { constants } from './constants';

const initialState = {
  data:[],
};

export function reducer(state = initialState, action) {



  switch (action.type) {

    case constants.LOAD_REQUEST:
      return {
        ...state,
        errors: null,
        
      };
    case constants.LOAD_SUCCESS:
      return {
        ...state,
        ...action.data,
       
      };
    case constants.LOAD_FAILURE:
      return {
      ...state,
        errors: {
          ...action.error.errors
        }
      }
     
   

    default:
      return state;
  }
}