import React from 'react';
import {  CardContent, Box} from '@mui/material';
import { Card } from '@material-ui/core';
import  '../assets/components/text-infobox.scss';


export default function TextInfobox({ children, status}) {

 

  return <Card 
    className={'TextInfobox' + ' ' + (status || 'info')} 
    elevation={0}
    >
      
      <Box 
        display="flex" 
        flexDirection="row" 
        justifyContent="stretch" 
        alignSelf="center"
        padding={1} 
        minHeight="105px">
        <Box className="TextInfoboxBar"
          padding={1} 
          paddingRight={0}
          ></Box>
        <CardContent  
          className="TextInfoboxContent"
          sx={{ paddingTop:0, paddingBottom:0}}  >
            {children}
        </CardContent>
      </Box>

     
    </Card>
  
   ;
 

}


