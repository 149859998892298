import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

const Privacy = (props) => {
  return (
    <>
      <main>
        <section className="p-4 process">
          <div className="container">
            <h2 className="my-5 text-center">Privacy policy</h2>
            <p className="col fs-7">
              <span style={{ fontWeight: "bold" }}>Denuncia.io S.r.l.</span>, in
              qualità di titolare del trattamento, informa ai sensi dell’art. 13
              Regolamento UE n. 2016/679 (“
              <span style={{ fontWeight: "bold" }}>GDPR</span>”) che i dati
              forniti dagli utenti (gli “
              <span style={{ fontWeight: "bold" }}>Interessato</span>” o l’“
              <span style={{ fontWeight: "bold" }}>Utente</span>”) tramite il
              sito web www.denuncia.io (il “
              <span style={{ fontWeight: "bold" }}>Sito</span>”),
              indipendentemente dalle modalità e dallo strumento utilizzato,
              saranno trattati con le modalità e per le finalità seguenti.
              <br />
              <br />
              <br />
              <strong>
                1. Il Titolare del trattamento dei dati personali{" "}
              </strong>
              <br />
              <br />
              Titolare del trattamento è Denuncia.io S.r.l., con sede legale in
              20121 Milano - Via del Lauro, 9 (di seguito, il “
              <span style={{ fontWeight: "bold" }}>
                Titolare del Trattamento
              </span>
              ”).
              <br />
              <br />
              Il Titolare del Trattamento mette a disposizione il seguente
              indirizzo di posta elettronica per ogni comunicazione:
              privacy@denunciaonline.eu
              <br />
              <br />
              Il Titolare del Trattamento potrà designare uno o più responsabili
              del trattamento dei Dati Personali ai sensi dell’articolo 28 del
              GDPR, che, per conto del Titolare del Trattamento, forniscono
              specifici servizi elaborativi o attività connesse, strumentali o
              di supporto adottando tutte quelle misure tecniche e organizzative
              adeguate a tutelare i diritti, le libertà e i legittimi interessi
              che sono riconosciuti per legge agli Interessati.
              <br />
              <br />
              <br />
              <strong>2. Descrizione del trattamento</strong>
              <br />
              <br />
              Il trattamento avrà ad oggetto singole operazioni, o un complesso
              di operazioni, dei seguenti dati personali forniti
              dall’Interessato in occasione della fruizione dei servizi resi dal
              Titolare del Trattamento, tramite il Sito, come descritto nella
              seguente tabella (i “<strong>Dati Personali</strong>” o i “
              <strong>Dati</strong>”):
              <br />
              <br />
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      <strong>Tipologia</strong>
                    </th>
                    <th>
                      <strong>Finalità del Trattamento</strong>
                    </th>
                    <th>
                      <strong>Base Giuridica</strong>
                    </th>
                    <th>
                      <strong>Periodo di Conservazione</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="2">
                      <strong>
                        Dati di Identificazione forniti per registrarsi al Sito
                      </strong>
                      : nome, cognome, email.
                    </td>

                    <td>
                      <ul>
                        <li>
                          Processare una richiesta di registrazione al Sito.
                        </li>
                        <li>
                          Aggiornare l’utente sullo stato di avanzamento della
                          denuncia, se effettuata.
                        </li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per tutta la durata del rapporto contrattuale e sino alla
                      cancellazione dell’account. In ogni caso, i Dati di
                      identificazione non potranno essere conservati per un
                      periodo superiore a dieci (10) anni dalla data di
                      registrazione al Sito
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <ul>
                        <li>
                          Inviare comunicazioni connesse all’attività con
                          riferimento alla quale l’Interessato ha fornito i
                          propri Dati;
                        </li>
                        <li>gestire, migliorare e mantenere il Sito.</li>
                      </ul>
                    </td>

                    <td>
                      Legittimo interesse del Titolare del Trattamento Articolo
                      6 paragrafo 1 lettera f) del GDPR).
                    </td>

                    <td>
                      Per la durata del rapporto contrattuale e per ue (2) anni
                      dalla registrazione al Sito.
                    </td>
                  </tr>

                  <tr>
                    <td rowSpan="3">
                      <strong>
                        {" "}
                        Dati Identificativi dell’Interessato forniti per
                        compilare la denuncia
                      </strong>
                      : nome, cognome, codice fiscale, numero di telefono,
                      e-mail o PEC, luogo e data di nascita, luogo e indirizzo
                      di residenza, estremi del documento di riconoscimento (a
                      scelta tra carta di identità, passaporto o patente di
                      guida in corso di validità), luogo in cui si trova
                      l’utente al momento della compilazione del form.
                    </td>

                    <td>
                      <ul>
                        <li>Elaborare il testo della denuncia.</li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il periodo di compilazione della denuncia tramite il
                      form nonché per la durata del rapporto contrattuale sino
                      alla richiesta di cancellazione dell’account. I Dati
                      Identificativi potranno essere in ogni caso conservati per
                      un periodo massimo di 10 anni
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <ul>
                        <li>
                          Adempiere agli obblighi stabiliti dalla legge, da un
                          regolamento, dal diritto comunitario o da un ordine
                          dell'Autorità.
                        </li>
                      </ul>
                    </td>

                    <td>
                      <ul>
                        <li>
                          Adempiere ad un obbligo legale al quale è soggetto il
                          Titolare del Trattamento (Articolo 6 paragrafo 1
                          lettera c) del GDPR).
                        </li>
                      </ul>
                    </td>

                    <td rowSpan="2">
                      Per il tempo necessario ai sensi di legge. In ogni caso,
                      per un periodo massimo di dieci (10) anni.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <ul>
                        <li>
                          Esercitare i diritti del Titolare del Trattamento, ad
                          esempio per esercitare un diritto in sede giudiziaria.
                        </li>
                      </ul>
                    </td>

                    <td>
                      <ul>
                        <li>
                          Legittimo interesse del Titolare del Trattamento
                          (Articolo 6 paragrafo 1 lettera f) del GDPR).
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Dati forniti spontaneamente dall’Interessato nella
                        compilazione della Denuncia, compresa la compilazione
                        del form “Raccontaci il tuo caso”.
                      </strong>
                    </td>

                    <td>
                      <ul>
                        <li>Elaborare il testo della Denuncia.</li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il periodo di compilazione della Denuncia tramite il
                      form nonché per la durata del rapporto contrattuale sino
                      alla richiesta di cancellazione dell’account. I Dati
                      Identificativi potranno essere in ogni caso conservati per
                      un periodo massimo di 10 anni.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Dati forniti spontaneamente dall’Interessato nell’ambito
                        del Servizio di consulenza.
                      </strong>
                    </td>

                    <td>
                      <ul>
                        <li>
                          Elaborare il testo della Denuncia e dare seguito alle
                          richieste dell’Interessato.
                        </li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il periodo di compilazione della Denuncia tramite il
                      form nonché per la durata del rapporto contrattuale sino
                      alla richiesta di cancellazione dell’account. I Dati
                      Identificativi potranno essere in ogni caso conservati per
                      un periodo massimo di 10 anni.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Dati forniti spontaneamente dall’Interessato nella
                        compilazione della Denuncia.
                      </strong>
                    </td>

                    <td>
                      <ul>
                        <li>Elaborare il testo della denuncia.</li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il periodo di compilazione della denuncia tramite il
                      form nonché per la durata del rapporto contrattuale sino
                      alla richiesta di cancellazione dell’account. I Dati
                      Identificativi potranno essere in ogni caso conservati per
                      un periodo massimo di 10 anni.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Dati forniti dall’Interessato per particolari tipologie
                        di Denunce ovvero di altri soggetti coinvolti
                        nell’ipotesi di reato (ove applicabili), tra cui:
                      </strong>

                      <p className="mt-3">
                        <span style={{ textDecoration: "underline" }}>
                          Furto di oggetti
                        </span>
                        , ad esempio: numero di carta di credito/bancomat, dati
                        del documento di identità, dati della patente di guida,
                        dati della tessera sanitaria, dati badge di lavoro, dati
                        tessera abbonamento mezzi, dati tessera dell’ordine
                        professionale di appartenenza.
                      </p>
                      <p>
                        <span style={{ textDecoration: "underline" }}>
                          Furto di veicoli
                        </span>
                        , ad esempio: numero di targa.
                      </p>

                      <p>
                        <span style={{ textDecoration: "underline" }}>
                          Disturbo della quiete
                        </span>
                        , ad esempio: indirizzo del luo go/immobile dove è avve
                        nuto l’evento, dati di eventuali testimoni.
                      </p>

                      <p>
                        <span style={{ textDecoration: "underline" }}>
                          Danneggiamento veicoli
                        </span>
                        , ad esempio: targa, descrizione del luogo dove è av
                        venuto il danno.
                      </p>

                      <p>
                        <span style={{ textDecoration: "underline" }}>
                          Violazione account email
                        </span>
                        , ad esempio: indirizzo email, indirizzi email dei
                        destinatari delle ultime email inviate.
                      </p>
                      <p>
                        <span style={{ textDecoration: "underline" }}>
                          Acquisti online non autorizzati/non ricevuti
                        </span>
                        , ad esempio: dati della carta di credito.
                      </p>
                    </td>

                    <td>
                      <ul>
                        <li>Elaborare il testo della denuncia.</li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il periodo di compilazione della denuncia tramite il
                      form nonché per la durata del rapporto contrattuale sino
                      alla richiesta di cancellazione dell’account. I Dati
                      Identificativi potranno essere in ogni caso conservati per
                      un periodo massimo di 10 anni.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Dati forniti spontaneamente dall’Interessato nella chat
                        con o senza l'operatore.
                      </strong>
                    </td>

                    <td>
                      <ul>
                        <li>Rispondere alle richieste degli Interessati.</li>
                      </ul>
                    </td>

                    <td>
                      Esecuzione di un contratto di cui l'Interessato è parte o
                      esecuzione di misure precontrattuali adottate su richiesta
                      dello stesso (Articolo 6 paragrafo 1 lettera b) del GDPR).
                    </td>

                    <td>
                      Per il tempo necessario a soddisfare le richieste degli
                      Interessati ovvero per l’esecuzione dei servizi. In ogni
                      caso, questi dati non potranno essere conservati per un
                      periodo superiore a dieci (10) anni dal soddisfacimento
                      delle richieste pervenute dall’Interessato.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Dati di navigazione</strong>:
                      <ul>
                        <li>
                          indirizzi IP, indirizzi in notazione URI/URL (Uniform
                          Resource Identifier/Locator) delle risorse richieste,
                          l’orario della richiesta, il metodo utilizzato nel
                          sottoporre la richiesta al server, la dimensione del
                          file ottenuto in risposta, il codice numerico
                          indicante lo stato della risposta data dal server
                          (buon fine, errore, ecc.);
                        </li>
                        <li>
                          altri parametri relativi al sistema operativo e
                          all’ambiente informatico utilizzato dall’Interessato.
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>
                          Ricavare informazioni statistiche anonime sull’uso del
                          Sito e per controllarne il corretto funzionamento.
                        </li>
                      </ul>
                    </td>
                    <td>
                      Legittimo interesse del Titolare del Trattamento (Articolo
                      6 paragrafo 1 lettera f) del GDPR).
                    </td>
                    <td>
                      I dati di navigazione saranno conservati per il tempo
                      necessario allo svolgimento delle attività di analisi e di
                      elaborazioni statistiche comparative, non superiore a 30
                      (trenta) giorni salve eventuali necessità di accertamento
                      da parte delle autorità competenti.
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        Cookie e altre tecnologie di lettura/archiviazione di
                        informazioni sul terminale dell’Interessato
                      </strong>
                    </td>
                    <td>
                      Si rimanda alla “Cookie Policy”, disponibile al seguente
                      link:{" "}
                      <a href="https://www.denunciaonline.eu/cookie-policy">
                        https://www.denunciaonline.eu/cookie-policy
                      </a>
                    </td>
                    <td>
                      Si rimanda alla “Cookie Policy”, disponibile al seguente
                      link:{" "}
                      <a href="https://www.denunciaonline.eu/cookie-policy">
                        https://www.denunciaonline.eu/cookie-policy
                      </a>
                    </td>
                    <td>
                      Si rimanda alla “Cookie Policy”, disponibile al seguente
                      link:{" "}
                      <a href="https://www.denunciaonline.eu/cookie-policy">
                        https://www.denunciaonline.eu/cookie-policy
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                {" "}
                Si precisa che, con riferimento ai dati di navigazioni, le
                informazioni raccolte, pur non essendo destinate ad essere
                associate a soggetti identificati, per loro natura, se asso-
                ciate ad altri Dati detenuti da terzi (es. internet service
                provider), potrebbero permet- tere l’identificazione degli
                Interessati (es., indirizzi IP, nomi a dominio dei pc
                utilizzati, indirizzi URL delle risorse richieste, orario della
                richiesta, codice numerico relativo allo stato della risposta
                data dal server).
              </p>
              <strong>3. Modalità del trattamento</strong>
              <p>
                Il trattamento dei Dati Personali: <br />
                (a) è realizzato per mezzo delle operazioni indicate
                all’Articolo 4, co. 1, n. 2 del GDPR e precisamente: raccolta,
                registrazione, organizzazione, conservazione, consultazione,
                elaborazione, modificazione, selezione, estrazione, raffronto,
                utilizzo, interconnessione, blocco, comunicazione, cancellazione
                e distruzione dei Dati;
                <br /> (b) è effettuato anche con l’ausilio di mezzi elettronici
                o comunque automatizzati; <br /> (c) è svolto anche mediante
                l’utilizzo di posta elettronica o di altre tecniche di
                comunicazione a distanza.
              </p>
              <strong>4. Trasferimento dei Dati Personali</strong>
              <p>
                La gestione e la conservazione dei Dati avverranno
                prioritariamente in Europa, su server di società terze
                incaricate e debitamente nominate quali responsabili del
                trattamento.
              </p>
              <p>
                Il Titolare del Trattamento può fornire l’accesso al Sito e ai
                servizi ivi indicati anche in altri Paesi, nel qual caso il
                trasferimento dei Dati in tali Paesi è strettamente limitato
                all’effettiva necessità di esserne a conoscenza. Il Titolare del
                Trattamento adotterà le misure necessarie per proteggere i Dati
                Personali degli Utenti e impedire l’accesso non autorizzato.
              </p>
              <p>
                Nelle ipotesi in cui i Dati Personali siano trasferiti nei
                sistemi usati dal Titolare del Trattamento e/o di società terze
                incaricate e debitamente nominate quali Responsabili del
                trattamento anche al di fuori dell’Unione Europea, il Titolare
                del Trattamento garantisce l’applicazione delle clausole
                contrattuali standard della Commissione Europea per garantire un
                trasferimento internazionale sicuro dei dati personali, in base
                agli artt. 44, 45 e 46 del GDPR.
              </p>
              <strong>5. Comunicazione dei Dati a terze parti</strong>
              <p>
                Al fine della corretta erogazione dei servizi offerti tramite il
                Sito, il Titolare del Trattamento, direttamente e/o tramite
                terzi debitamente nominati responsabili del trattamento,
                inoltrerà i Dati contenuti nelle denunce alle competenti
                autorità giudiziarie, previa raccolta del relativo consenso da
                parte dell’Interessato.
              </p>
              <strong>6. Misure di sicurezza</strong>
              <p>
                Il Titolare del Trattamento ha adottato una varietà di misure di
                sicurezza per proteggere i Dati contro il rischio di perdita,
                abuso o alterazione, coerentemente alle misure espresse
                nell’Articolo 32 del GDPR.
              </p>
              <strong>
                7. Conseguenze della mancata comunicazione dei Dati Personali
              </strong>
              <p>
                Ferma la facoltà dell’Interessato di fornire i Dati Personali al
                Titolare del Trattamento, il conferimento dei Dati Personali può
                essere: <br />
                <p className="mt-2">
                  (a) obbligatorio ai fini dell’erogazione dei servizi
                  accessibili tramite il Sito e per finalità connesse
                  all’adempimento di obblighi previsti dalle leggi e/o
                  regolamenti applicabili, nonché da disposizioni impartite
                  dalle competenti autorità/organi di vigilanza e/o controllo;
                </p>
                <p className="mt-2">
                  (b) facoltativo con riferimento ai dati forniti spontaneamente
                  dell’Interessato.
                </p>
                <p>
                  L’eventuale rifiuto dell’Interessato di fornire i Dati
                  Personali al Titolare del Trattamento, potrebbe comportare
                  l’impossibilità per il Titolare del Trattamento di fornire i
                  servizi richiesti e rendere disponibile l’accesso al Sito.
                </p>
                <p>
                  Inoltre, si prega di considerare che la revoca di uno o più
                  permessi e/o consensi non conferiti dall’Utente può comportare
                  delle conseguenze sul corretto funzionamento e/o sulla
                  possibilità di accedere e/o utilizzare correttamente il Sito
                  e/o erogare i servizi da parte del Titolare del Trattamento.
                </p>
              </p>
              <strong>8. Conservazione e cancellazione dei Dati</strong>
              <p>
                Il periodo di conservazione dei Dati Personali è indicato nella
                tabella al precedente punto 2.
              </p>
              <p>
                Al termine del periodo di conservazione i Dati Personali saranno
                cancellati. Pertanto, allo spirare di tale termine il diritto di
                accesso, cancellazione, rettificazione ed il diritto alla
                portabilità dei Dati Personali non potranno più essere
                esercitati dall’Utente.
              </p>
              <p>
                I Dati Personali saranno conservati a mezzo di archivi cartacei
                e informatici, inclusi dispositivi portatili, adottando misure
                idonee a garantirne la sicurezza e a limitarne l’accesso
                esclusivamente al personale autorizzato dal Titolare del
                Trattamento e nello stretto ambito delle finalità sopra
                indicate.
              </p>
              <strong>9. Diritti dell’Interessato</strong>
              <p>
                L’Interessato può esercitare i diritti previsti dal Capo III del
                GDPR nei limiti ed alle condizioni ivi previste: <br />
                <p className="mt-2">
                  (a) <strong>accesso ai Dati (art. 15)</strong>: l’Interessato
                  ha il diritto di ottenere dal Titolare del Trattamento la
                  conferma che sia o meno in corso un trattamento di Dati
                  Personali che lo riguardano e, in tal caso, ottenere l’accesso
                  ai Dati Personali in un formato elettronico di uso comune ed
                  alcune informazioni sul trattamento (es. finalità, categorie
                  di Dati trattati, destinatari, trasferimenti extra UE,
                  attuazione di attività di profilazione, ecc.);
                </p>
                <p className="mt-2">
                  (b)<strong> rettifica dei Dati (art. 16)</strong>:
                  l’Interessato ha il diritto di ottenere la rettifica dei Dati
                  Personali inesatti che lo riguardano senza ingiustificato
                  ritardo e/o l’integrazione dei Dati Personali incompleti,
                  anche fornendo una dichiarazione integrativa;
                </p>
                <p className="mt-2">
                  (c){" "}
                  <strong>
                    cancellazione dei Dati o “diritto all’oblio” (art. 17)
                  </strong>
                  : l’Interessato ha il diritto di ottenere dal Titolare del
                  Trattamento la cancellazione dei Dati Personali che lo
                  riguardano senza ingiustificato ritardo e il Titolare del
                  Trattamento ha l’obbligo di cancellare senza ingiustificato
                  ritardo i Dati Personali;
                </p>
                <p className="mt-2">
                  (d) <strong>limitazione del trattamento (art. 18)</strong>:
                  l’Interessato ha il diritto di ottenere dal Titolare del
                  Trattamento la limitazione del trattamento;
                </p>
                <p className="mt-2">
                  (e) <strong>portabilità dei Dati (art. 20)</strong>:
                  l’Interessato ha il diritto di ricevere in un formato
                  strutturato, di uso comune e leggibile da dispositivo
                  automatico i Dati Personali che lo riguardano forniti ad un
                  Titolare del Trattamento e ha il diritto di trasmettere tali
                  Dati a un altro Titolare del Trattamento senza impedimenti da
                  parte del Titolare del Trattamento cui li ha forniti;
                </p>
                <p className="mt-2">
                  (f) <strong>opposizione al trattamento (art. 21)</strong>:
                  l’Interessato ha il diritto di opporsi in qualsiasi momento,
                  per motivi connessi alla sua situazione particolare, al
                  trattamento dei Dati Personali che lo riguardano ai sensi
                  dell’articolo 6, paragrafo 1, lettere e) o f) del GDPR,
                  compresa la profilazione sulla base di tali disposizioni.
                </p>
              </p>
              <strong>10. Modalità di esercizio dei diritti</strong>
              <p>
                L’Interessato potrà in qualsiasi momento esercitare i diritti
                inviando:{" "}
                <p className="mt-2">
                  (a) una e-mail all’indirizzo privacy@denunciaonline.eu; <br />{" "}
                  (b) una raccomandata A/R a Denuncia.io S.r.l., con sede legale
                  in 20121 – Via del Lauro, 9.
                </p>
                <p>
                  Il Titolare del Trattamento si impegna a fornire
                  all’Interessato le informazioni relative all’azione intrapresa
                  riguardo a una richiesta di esercizio dei diritti senza
                  ingiustificato ritardo e, comunque, al più tardi entro un
                  termine di 30 (trenta) giorni dal ricevimento della richiesta
                  stessa, estendibile fino a 3 mesi solo in casi di particolare
                  complessità.
                </p>
                <p>
                  Le eventuali rettifiche o cancellazioni o limitazioni del
                  trattamento effettuate su esplicita richiesta dell’Interessato
                  (salvo ove ciò si riveli impossibile o implichi uno sforzo
                  sproporzionato) saranno comunicate dal Titolare del
                  Trattamento a ciascuno dei destinatari cui sono stati
                  trasmessi i Dati Personali. Il Titolare del Trattamento potrà
                  comunicare all’Interessato i riferimenti dei destinatari,
                  qualora richiesto.
                </p>
              </p>
              <strong>11. Diritto di reclamo</strong>
              <p>
                Gli Interessati che ritengono che il trattamento dei Dati
                Personali avvenga in violazione di quanto previsto dal GDPR
                hanno il diritto di proporre reclamo all’Autorità Garante per la
                Protezione dei Dati Personali (Garante Privacy) via e-mail,
                all’indirizzo garante@gpdp.it oppure urp@gpdp.it, via fax
                06.696773785, oppure via posta al Garante Privacy per la
                protezione dei Dati Personali che ha sede in Roma (Italia),
                Piazza Venezia n. 11 – Cap 00187, o alternativamente mediante
                ricorso all’Autorità giudiziaria.
              </p>
              <strong>12. Responsabile e incaricati</strong>
              <p>
                L’elenco aggiornato dei responsabili e degli incaricati al
                trattamento è custodito presso la sede del Titolare del
                Trattamento.
              </p>
              <strong>13. Modifiche alla presente informativa</strong>
              <p>
                La presente informativa può subire variazioni. Si consiglia,
                quindi, di controllare regolarmente la presente informativa e di
                riferirsi alla versione più aggiornata.
              </p>
              <p className="ft-5 mt-4">Ultimo aggiornamento: 28/11/2023</p>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default Privacy;
