import BlogContainer from "components/ComponentHtml/BlogContainer";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import NavbarBlog from "components/ComponentHtml/NavbarBlog";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Blog() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    setTimeout(() => {
      setIsLoading(false);
    }, 10);

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div style={{ display: "none" }} />
      ) : (
        <>
          <NavbarBlog />
          <BlogContainer />
          <FooterOnePage />
        </>
      )}
    </>
  );
}
