
export function authHeader(addContentType = true) {

    var headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    if(addContentType) {
        headers.append("Content-Type", "application/json");
    }

    // return authorization header with jwt token
    let report_state = JSON.parse(localStorage.getItem('report_state'));

    const token = report_state?.report?.customer?.token;

    
    
    if (token) {
        headers.append("Authorization", 'Bearer ' + token);
    }
    
    return headers;
}

export function authHeaderMultipart(addContentType = true) {

    var headers = new Headers();
    headers.append("Accept", "application/json");
    if(addContentType) {
        headers.append("Content-Type", "multipart/form-data");
    }

    // return authorization header with jwt token
    let report_state = JSON.parse(localStorage.getItem('report_state'));

    const token = report_state?.report?.customer?.token;



    if (token) {
        headers.append("Authorization", 'Bearer ' + token);
    }

    return headers;
}

export function authHeaderDownload(addContentType = true) {

    var headers = new Headers();
    headers.append("Accept", "application/json");
    if(addContentType) {
        headers.append("Content-Type", "application/json");
    }

    // return authorization header with jwt token
    let report_state = JSON.parse(localStorage.getItem('report_state'));

    const token = report_state?.report?.customer?.token;



    if (token) {
        headers.append("Authorization", 'Bearer ' + token);
    }

    return headers;
}