import { Button, Typography } from "@mui/material";
import React from "react";
import "../assets/components/text-simple.scss";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, triggerLogout, ...props } = this.props;
    return (
      <Button onClick={triggerLogin} onLoad={triggerLogout} {...props}>
        {children}
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
