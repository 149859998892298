import React from "react";
import { CardContent, Typography, Box, Chip, Button } from "@mui/material";
import { Card } from "@material-ui/core";
import { history } from "../history";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../assets/components/report-data-card.scss";
import Moment from "react-moment";
import "moment/locale/it";

const StatusChip = ({ status }) => {
  if ("NEW_DOCUMENT" === status)
    return (
      <Typography className="CardStatus">
        <Chip
          className="CardStatusCircle"
          // label="&nbsp;&nbsp;"
          sx={{ backgroundColor: "#FFD600 !important" }}
        />{" "}
        in lavorazione
      </Typography>
    );
  if ("COMPLETE" === status)
    return (
      <Typography className="CardStatus">
        <Chip
          className="CardStatusCircle"
          // label="&nbsp;&nbsp;"
          color="success"
        />{" "}
        depositata
      </Typography>
    );
  if ("DISCARDED" === status)
    return (
      <Typography className="CardStatus">
        <Chip
          className="CardStatusCircle"
          // label="&nbsp;&nbsp;"
          // color="warning"
          sx={{ backgroundColor: "#FF0F00 !important" }}
        />{" "}
        documento rifiutato
      </Typography>
    );

  return (
    <Typography className="CardStatus">
      <Chip className="CardStatusCircle" color="warning" /> da completare
    </Typography>
  );
};

export default function ReportDataCard({ report, showCta }) {
  const onClick = () => {
    history.push("/build/" + report.id);
  };

  return (
    <Card className="ReportDataCard" elevation={0}>
      <Box
        className="ReportDataCardIcon"
        padding={1}
        paddingRight={0}
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        {" "}
        <div className="icon-dashboard-denunce-customer-card" />
      </Box>
      <CardContent sx={{ flex: "1 0" }}>
        <Box textAlign="left">
          <StatusChip status={report.status} />
          <Typography className="CardDate">
            Denuncia del{" "}
            <strong>
              <Moment locale="it" format="DD MMMM YYYY">
                {report.date}
              </Moment>
            </strong>
          </Typography>
          <Typography className="CardType">#{report.id}</Typography>
        </Box>
      </CardContent>

      {showCta === true && (
        <Box padding={1}>
          <Button
            className="button-card"
            variant="contained"
            onClick={onClick}
            size="large"
            sx={{
              height: "100%",
              minWidth: 0,
              padding: "0.2em",
              backgroundColor: "#58979d",
            }}
          >
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          </Button>
        </Box>
      )}
    </Card>
  );
}
