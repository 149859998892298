import { Typography } from '@mui/material';
import React from 'react';
import '../assets/components/text-header-primary.scss';


const TextHeaderPrimary = ({children}) => {
    return <div className="TextHeaderPrimary">
                <Typography variant="h4">{children}</Typography>
        </div>
}

export default TextHeaderPrimary;