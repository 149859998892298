import React from "react";
import "../assets/components/facebook-login.scss";
import SocialButton from "./SocialButton";
import { actions } from "../_model/report/actions";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";

const FacebookLogin = ({ children }) => {
  const dispatch = useDispatch();

  const handleSocialLogin = (e) => {
    const { email, name, id } = e.profile;
    dispatch(actions.socialLogin(email, name, e._provider, id));
  };

  const handleSocialLoginFailure = (e) => {
    console.log("Facebook Login Failed", e);
  };

  return (
    <div className="FacebookLogin">
      <SocialButton
        fullWidth
        size="large"
        variant="outlined"
        provider="facebook"
        // appId="967631600625946"
        // appId="661216108094895"
        // appId="967631600625946"
        appId="342568961536373"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
      >
        <svg
          width="15"
          height="28"
          viewBox="0 0 15 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 0H10.9091C9.1008 0 7.36657 0.737498 6.08791 2.05025C4.80925 3.36301 4.09091 5.14348 4.09091 7V11.2H0V16.8H4.09091V28H9.54545V16.8H13.6364L15 11.2H9.54545V7C9.54545 6.6287 9.68912 6.2726 9.94485 6.01005C10.2006 5.7475 10.5474 5.6 10.9091 5.6H15V0Z"
            fill="white"
          />
        </svg>
        <Typography style={{ fontSize: "14px", marginLeft: "20px" }}>
          Accedi con Facebook
        </Typography>
      </SocialButton>
    </div>
  );
};

export default FacebookLogin;
