//import { userService } from "../_services/user.service";

import { data } from "jquery";

export default function handleResponse(response) {
  if (response.status === 401) {
    localStorage.removeItem("report");
  }

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = data || response.statusText;
      return Promise.reject(error);
    }

    if (data.denuncia_pdf) {
      const linkSource = `data:application/pdf;base64,${data.denuncia_pdf}`;

      return { data: { linkSource } };
    }

    return data;
  });
}
